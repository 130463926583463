export const locale = {
  lang: 'ru',
  data: {
    GAME_HELP: {
      TITLE: "Руководство",
      STEP_0_TILE: "Uniteverse",
      STEP_0_TEXT: "Узнайте, как это работает",
      STEP_0_BUTTON: "Продолжить",

      STEP_1_TITLE: "Главная страница: Вход в шахту Uniteverse",
      STEP_1_TEXT: "Эта страница служит входом в шахту Uniteverse, где вы можете просмотреть и получить доступ к ключевой информации. Здесь отображаются ваши основные метрики и важные статусы, отражающие ваш текущий прогресс и состояние.",

      STEP_2_TITLE: "Общий доход и баланс",
      STEP_2_TEXT: "На этой панели вы можете отслеживать текущий баланс энергетических монет и ресурсов.",

      STEP_3_TITLE: "Добыча энергии",
      STEP_3_TEXT: "С помощью этого устройства вы можете добывать энергию NTC (NetCharge) каждые 24 часа. Собирайте энергию ежедневно, чтобы полностью заряжать свои батареи в устройстве обработки руды. Зарядка батареи увеличивает количество необработанной руды, которую можно обработать, тем самым повышая ваш общий урожай.",

      STEP_4_TITLE: "Ключ доступа и уровни",
      STEP_4_TEXT: "Активируя ключ доступа к уровню, вы получаете доступ к уровню для участия в запуске квантовых волн, за которым следует обработка руды и добыча Bitforce. При активации и продлении доступа вы также получаете чип, который автоматически устанавливается в устройство запуска квантовой волны. После запуска квантовой волны вы можете обработать руду, добытую в этой квантовой волне." +
          "\n\n" +
          "В верхней части экрана вы можете отслеживать статус текущего запуска квантовой волны. Ниже находится раздел с поперечным планом шахты, указывающим глубину уровней и отображающим ваши активные доступы к уровням.",

      STEP_5_TITLE: "Вход на уровни",
      STEP_5_TEXT: "Чтобы войти, нажмите на любой активный уровень. Здесь вы можете управлять процессами обработки и добычи ресурсов.",

      STEP_6_TITLE: "Страница шахты",
      STEP_6_TEXT: "На этой странице вы можете увидеть уровни, к которым у вас есть доступ. Эти уровни содержат различные машины.",

      STEP_7_TITLE: "Панель управления",
      STEP_7_TEXT: "На этой панели вы можете отслеживать, сколько ваших чипов участвуют в запуске текущей квантовой волны. Кроме того, она также отображает, к каким уровням у вас есть доступ.",

      STEP_8_TITLE: "Пространство уровня",
      STEP_8_TEXT: "Каждое пространство уровня содержит свои уникальные машины. Здесь вы можете увидеть время доступа к текущему уровню, статус готовности машин для запуска квантовой волны, а также обзор сети GameAf.",

      STEP_9_TITLE: "Продление уровня",
      STEP_9_TEXT: "Продлите доступ к уровню, который изначально предоставляется на 30 дней, чтобы продолжить его использование. Если предыдущие уровни не активны, продление доступно только с штрафом." +
          "\n\n" +
          "Когда вы продлеваете уровень, чип также выделяется на устройство для запуска квантовой волны того же уровня." +
          "\n\n" +
          "Просмотрите оставшиеся дни до истечения активации вашего текущего уровня.",

      STEP_10_TITLE: "Активация ключа доступа к уровню",
      STEP_10_TEXT: "В этом примере мы активируем доступ к уровню 3.",

      STEP_11_TITLE: "Активация ключа доступа к уровню",
      STEP_11_TEXT: "Это окно показывает количество чипов и время доступа, которые вы получите. Оплата производится в HC. Если у вас его нет на счете, вы можете выбрать первый вариант, который автоматически купит HC на рынке по текущему курсу." +
          "\n\n" +
          "При выборе нескольких активаций или продлений первый полученный чип для этого уровня будет установлен в машине для запуска текущей квантовой волны. Второй чип будет использован для запуска квантовой волны через 30 дней после запуска волны с первым чипом. Третий чип будет активирован через 30 дней после запуска волны со вторым чипом и так далее.",

      STEP_12_TITLE: "Квантовое пространство",
      STEP_12_TEXT: "В следующем пространстве доступны две общие машины для всех уровней. Первая машина обрабатывает руду в квантовую руду, а вторая машина добывает Bitforce.",

      STEP_13_TITLE: "Машина обработки руды",
      STEP_13_TEXT: "Давайте подробнее рассмотрим, как работает машина обработки руды." +
          "\n\n" +
          "Начнем с начала: квантовая волна будет инициирована, когда активируются 88 устройств. Эта волна извлечет необработанную руду. Чипы, участвующие в запуске волны, определят потенциальное максимальное количество руды, которую вы можете обработать в этом устройстве за 30 дней. После этих 30 дней у вас будет возможность собрать квантовую руду." +
          "\n\n" +
          "Чтобы обработать руду, вам нужно поддерживать заряд батарей. По умолчанию у вас есть одна батарея, но вскоре у вас будет возможность приобрести дополнительную батарею. Таким образом, если вы полностью участвуете в обработке руды, вы получите максимальное возможное количество квантовой руды. Однако если вы не участвуете в обработке руды, вы ничего не получите.",

      STEP_14_TITLE: "Зарядка батареи",
      STEP_14_TEXT: "Здесь вы можете увидеть, сколько квантовой руды вы можете потенциально получить после обработки руды, а также сколько времени осталось для обработки. Чтобы зарядить батарею, нажмите кнопку обновления.",

      STEP_15_TITLE: "Зарядка батареи",
      STEP_15_TEXT: "Введите желаемое количество энергии для зарядки батареи и нажмите 'Применить'.",

      STEP_16_TITLE: "Завершенная квантовая волна",
      STEP_16_TEXT: "Вот как выглядит завершенная волна обработки руды. Теперь вы можете собрать заслуженную квантовую руду."
    }
  },
};
