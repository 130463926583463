export const locale = {
  lang: 'hi',
  data: {
    MARKETING_TABEL: {
      FORCE_ID: 'फ़ोर्स आईडी',
      ORBIT: 'ऑर्बिट',
      WALLET: 'वॉलेट:',
      UV_TIER: 'UV टियर:',
      PROFIT: 'लाभ:',
      LINE: 'लाइन',
    },
  },
};
