export const locale = {
  lang: 'zh',
  data: {
    NOT_ACTIVATED: {
      HEADER: '您尚未激活此层级',
      DESCRIPTION: '提供30天的访问权限',
      OVERTAKING: '⬤ 超车',
      ACTIVATE: '激活',
    },
  },
};
