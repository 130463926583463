export const locale = {
  lang: 'id',
  data: {
    VESTING_TARIFFS: {
      TITLE: 'Pilih Tarif',
      BALANCES: 'Saldo Anda',
      NO_VESTING_BALANCE_INFO: 'Anda tidak memiliki saldo penahanan di akun Anda. Penahanan tidak tersedia tanpa saldo.',
      TARIFF_1_NAME: 'Tarif Vesting 2 Bulan',
      TARIFF_2_NAME: 'Tarif Penahanan 1 Tahun',
      TARIFF_3_NAME: 'Tarif Penahanan 2 Tahun',
      TARIFF_4_NAME: 'Tarif Penahanan 3 Tahun',
      TARIFF_5_NAME: 'Tarif Penahanan 4 Tahun',
      TARIFF_6_NAME: 'Tarif Penahanan 5 Tahun',
      DURATION: 'Durasi: ',
      TARIFF_1_DURATION: '2 Bulan',
      TARIFF_2_DURATION: '1 tahun',
      TARIFF_3_DURATION: '2 tahun',
      TARIFF_4_DURATION: '3 tahun',
      TARIFF_5_DURATION: '4 tahun',
      TARIFF_6_DURATION: '5 tahun',
      TARIFF_1_DESCRIPTION: 'Jika Anda memilih tarif ini, biaya pendanaan sebesar 50% akan dikenakan dari saldo total yang tersisa di akun vesting HC Anda. Total 50% HC akan tersedia.',
      TARIFF_2_DESCRIPTION: 'Dengan memilih tarif ini, pembayaran pendanaan sebesar 30% dari saldo penahanan HC Anda yang tersisa* akan diambil. Sehingga tersisa 70% HC yang dapat diakses.',
      TARIFF_3_DESCRIPTION: 'Dengan memilih tarif ini, pembayaran pendanaan sebesar 15% dari saldo penahanan HC Anda yang tersisa* akan diambil. Sehingga tersisa 85% HC yang dapat diakses.',
      TARIFF_4_DESCRIPTION: 'Rencana standar. Tidak memerlukan biaya dan tidak memberikan HC tambahan.',
      TARIFF_5_DESCRIPTION: 'Memungkinkan Anda mempertahankan saldo penahanan HC saat ini dan membagi 30% dari dana pendanaan.',
      TARIFF_6_DESCRIPTION: 'Memungkinkan Anda mempertahankan saldo penahanan HC saat ini dan membagi 70% dari dana pendanaan.',
      TARIFFS_COMMON_INFO: 'Penahanan HC terdiri dari dua tahap. Tahap pertama adalah pelepasan 10% dari saldo setiap pengguna, dengan pelepasan tambahan HC untuk perpanjangan tingkat pada bulan pertama. Tahap kedua adalah pelepasan sisa saldo 90% atau kurang jika ada partisipasi dalam pelepasan HC untuk perpanjangan tingkat. Pemilihan tarif penahanan hanya berlaku untuk tahap dua. Tarif penahanan 3 tahun diaktifkan secara default, jika Anda tidak berencana mengubahnya, Anda tidak perlu memilih apa pun di halaman ini.',
      TARIFFS_COMMON_INFO_WARNING: 'Pemilihan tarif hanya dapat dilakukan sekali. Mengubah tarif kedua kali akan menjadi tidak mungkin. Contoh: saat ini tarif 3 tahun dipilih secara default. Anda memilih tarif 4 tahun, setelah itu Anda tidak dapat mengubahnya.',
      TARIFFS_INFO: 'Semua token penahanan akan diterbitkan hingga akhir periode.',
      TARIFFS_DISABLED_BIG: 'Tarif 4 dan Tarif 5 akan tersedia nanti, setelah perhitungan volume dana.',
      TARIFFS_DISABLED_SMALL: "Tarif untuk 2 bulan, 1 tahun, dan 2 tahun akan tersedia nanti, setelah perhitungan volume dana selesai.",
      SELECT_TARIFF: 'Pilih Tarif',
      CURRENT_TARIFF: 'Tarif Anda',
      DEFAULT: 'default',
      USER_WILL_RECEIVE: 'Anda akan menerima hingga akhir periode:',
      FUND_WILL_RECEIVE: 'Pembayaran Anda ke dana pendanaan:',
      FUND_RECEIVED: 'Di dana:',
      FUNDING_AMOUNT: 'Jumlah dana',
      BURN: 'Akan dibakar 15%',
      BUYBACK_INFO: "Jika Anda tidak ingin menunggu untuk mendapatkan koin dari messenger, Anda dapat mengetahui tentang syarat tambahan",
      BUYBACK_LINK: "dengan mengklik di sini",
    },
  },
};
