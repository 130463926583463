export const locale = {
  lang: 'vi',
  data: {
    TABS: {
      ALL: 'Tất cả',
      ACTIVE: 'Đang hoạt động',
      COMPLETED: 'Đã hoàn thành',
    }
  },
};
