export const locale = {
  lang: 'zh',
  data: {
    MARKETING_TABEL: {
      FORCE_ID: '力量ID',
      ORBIT: '轨道',
      WALLET: '钱包:',
      UV_TIER: 'UV等级:',
      PROFIT: '利润:',
      LINE: '线路',
    },
  },
};
