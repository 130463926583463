export const locale = {
  lang: 'ru',
  data: {
    VESTING_TARIFFS: {
      TITLE: 'Выберите тариф',
      BALANCES: 'Ваш баланс',
      NO_VESTING_BALANCE_INFO: 'У Вас нет накопительного баланса на вашем счете. Накопление недоступно без баланса.',
      TARIFF_1_NAME: 'Тариф 2 месяца вестинга',
      TARIFF_2_NAME: 'Тариф 1 год вестинга',
      TARIFF_3_NAME: 'Тариф 2 года вестинга',
      TARIFF_4_NAME: 'Тариф 3 года вестинга',
      TARIFF_5_NAME: 'Тариф 4 года вестинга',
      TARIFF_6_NAME: 'Тариф 5 лет вестинга',
      DURATION: 'Срок: ',
      TARIFF_1_DURATION: '2 месяца',
      TARIFF_2_DURATION: '1 год',
      TARIFF_3_DURATION: '2 года',
      TARIFF_4_DURATION: '3 года',
      TARIFF_5_DURATION: '4 года',
      TARIFF_6_DURATION: '5 лет',
      TARIFF_1_DESCRIPTION: 'При выборе данного тарифа будет взята оплата процента фандинга в размере 50% от оставшегося* Вашего общего баланса вестинга HC. Итого останется доступно 50% HC.',
      TARIFF_2_DESCRIPTION: 'При выборе данного тариф будет взята оплата процента фандинга в размере 30% от оставшегося* Вашего общего баланса вестинга HC. Итого останется доступно 70% HC.',
      TARIFF_3_DESCRIPTION: 'При выборе данного тариф будет взята оплата процента фандинга в размере 15% от оставшегося* Вашего общего баланса вестинга HC. Итого останется доступно 85% HC.',
      TARIFF_4_DESCRIPTION: 'Стандартный план. Не требует затрат, и не принесет дополнительные HC.',
      TARIFF_5_DESCRIPTION: 'Позволит сохранить текущий баланс вестинга HC, и дополнительно разделить 30% из пула фандинга.',
      TARIFF_6_DESCRIPTION: 'Позволит сохранить текущий баланс вестинга HC, и дополнительно разделить 70% из пула фандинга.',
      TARIFFS_COMMON_INFO: 'Вестинг HC состоит из двух этапов. Первый этап освобождение 10% от баланса каждого пользователя, с дополнительным освобождением HC для продления тьиров в первый месяц. Второй этап вестинга остатков баланса 90% или меньше если было  участие в освобождении HC для продления тьиров. Выбор тарифного плана вестинга применяется только для этапа два. Тарифный план на 3 года активирован по умолчанию, если вы не собираетесь его менять то вам не нужно ничего выбирать на этой странице.',
      TARIFFS_COMMON_INFO_WARNING: 'Выбор тарифа осуществляется единоразово. Изменить тариф второй раз будет невозможно. Пример: сейчас по умолчанию выбран тариф на 3 года. Вы выбираете тариф на 4 года, после этого вы не сможете изменить его.',
      TARIFFS_INFO: 'Все накопительные токены будут выпущены до конца срока.',
      TARIFFS_DISABLED_BIG: 'Тариф 4 и Тариф 5 будут доступны позже, после расчета объемов фонда.',
      TARIFFS_DISABLED_SMALL: 'Тарифы на 2 месяца, 1 год и 2 года будут доступны позже, после расчета объемов фонда.',
      SELECT_TARIFF: 'Выберите тариф',
      CURRENT_TARIFF: 'Ваш тариф',
      DEFAULT: 'по умолчанию',
      USER_WILL_RECEIVE: 'Вы получите до конца периода:',
      FUND_WILL_RECEIVE: 'Ваша оплата в фонд фандинга:',
      FUND_RECEIVED: 'В фонде:',
      FUNDING_AMOUNT: 'Размер фонда',
      BURN: '15% будет сожжено',
      BUYBACK_INFO: 'Если вы не хотите ждать монеты из вестнига, то с дополнительными условиями можно ознакомиться',
      BUYBACK_LINK: 'нажав тут',
    },
  },
};
