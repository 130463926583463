export const locale = {
  lang: 'ru',
  data: {
    NOT_ACTIVATED: {
      HEADER: 'Вы еще не активировали этот тьир',
      DESCRIPTION: 'Доступ предоставляется на 30 дней',
      OVERTAKING: '⬤ Обгон',
      ACTIVATE: 'Активировать',
    },
  },
};
