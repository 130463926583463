export const locale = {
  lang: 'en',
  data: {
    ACTIVITY: {
      ID: 'From ID',
      NEW_REFERRAL: 'New referral',
      TIER: 'Tier ',
      ACTIVATION: 'activation',
      EXTENSION: 'extension',
      GETTING_FORCECOIN: 'Getting Holichain',
      DELTA_ACTIVATION: 'Delta activation',
      LOST_PROFIT: 'Lost profit',
      YOU: 'You',
    },
  },
};
