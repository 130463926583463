export const locale = {
  lang: 'ru',
  data: {
    TIME: {
      ACTIVE_PERIOD: 'Активный период окончен',
      ACCESS_GIVEN: 'Доступ предоставляется на 30 дней',
      EXTAND: 'Продлить',
    },
  },
};
