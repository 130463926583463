export const locale = {
  lang: 'ur',
  data: {
    VESTING_TARIFFS: {
      TITLE: 'تاریخچہ منتخب کریں',
      BALANCES: 'آپ کا بیلنس',
      NO_VESTING_BALANCE_INFO: 'آپ کے حساب میں کوئی جمع نہیں ہے۔ بغیر بیلنس کے جمع کرنے کا کوئی اختیار نہیں ہے۔',
      TARIFF_1_NAME: '2 ماہ کی ویسٹنگ شرح',
      TARIFF_2_NAME: '1 سال کی ویسٹنگ کی فیس',
      TARIFF_3_NAME: '2 سال کی ویسٹنگ کی فیس',
      TARIFF_4_NAME: '3 سال کی ویسٹنگ کی فیس',
      TARIFF_5_NAME: '4 سال کی ویسٹنگ کی فیس',
      TARIFF_6_NAME: '5 سال کی ویسٹنگ کی فیس',
      DURATION: 'مدت: ',
      TARIFF_1_DURATION: '2 ماہ',
      TARIFF_2_DURATION: '1 سال',
      TARIFF_3_DURATION: '2 سال',
      TARIFF_4_DURATION: '3 سال',
      TARIFF_5_DURATION: '4 سال',
      TARIFF_6_DURATION: '5 سال',
      TARIFF_1_DESCRIPTION: 'اگر آپ یہ شرح منتخب کرتے ہیں، تو آپ کے HC ویسٹنگ اکاؤنٹ کے باقی ماندہ بیلنس سے 50% فنڈنگ فیس وصول کی جائے گی۔ کل HC کا 50% دستیاب ہوگا۔',
      TARIFF_2_DESCRIPTION: 'اس ٹیرف کو منتخب کرنے پر ، آپ کے باقی* کل ویسٹنگ بیلنس کے 30٪ کی ادائیگی لی جائے گی۔ کل میں 70٪ HC دستیاب رہے گا۔',
      TARIFF_3_DESCRIPTION: 'اس ٹیرف کو منتخب کرنے پر ، آپ کے باقی* کل ویسٹنگ بیلنس کے 15٪ کی ادائیگی لی جائے گی۔ کل میں 85٪ HC دستیاب رہے گا۔',
      TARIFF_4_DESCRIPTION: 'معیاری پلان۔ کوئی خرچ نہیں کیا جاتا ہے اور کوئی اضافی HC نہیں حاصل ہوتا ہے۔',
      TARIFF_5_DESCRIPTION: 'موجودہ ویسٹنگ بیلنس HC کو محفوظ رکھنے اور فنڈنگ پول سے 30٪ تقسیم کرنے کی اجازت دیتا ہے۔',
      TARIFF_6_DESCRIPTION: 'موجودہ ویسٹنگ بیلنس HC کو محفوظ رکھنے اور فنڈنگ پول سے 70٪ تقسیم کرنے کی اجازت دیتا ہے۔',
      TARIFFS_COMMON_INFO: 'HC ویسٹنگ دو مراحل پر مشتمل ہوتی ہے۔ پہلی مرحلہ میں ہر صارف کے بیلنس کے 10٪ آزاد کرنے کے ساتھ ساتھ پہلے مہینے میں ٹیرفوں کو بڑھانے کے لئے اضافی HC کا آزاد کرنا شامل ہوتا ہے۔ دوسری مرحلہ میں ویسٹنگ بیلنس کے باقی حصے 90٪ یا اس سے کم (اگر HC کے آزاد کرنے میں شرکت کی گئی ہو) کو آزاد کرنا شامل ہوتا ہے۔ ویسٹنگ کے ٹیرف منتخب کرنے کا اطلاق صرف مرحلہ دو پر ہوتا ہے۔ 3 سال کا ٹیرف پہلے سے ہی چالو ہوتا ہے ، اگر آپ اسے تبدیل نہیں کرنا چاہتے تو آپ کو اس صفحے پر کچھ منتخب کرنے کی ضرورت نہیں ہے۔',
      TARIFFS_COMMON_INFO_WARNING: 'ٹیرف کا انتخاب صرف ایک بار کیا جاتا ہے۔ دوسری بار ٹیرف تبدیل کرنا ممکن نہیں ہوگا۔ مثال: ابھی پہلے سے 3 سال کی ٹیرف چنی گئی ہے۔ آپ 4 سال کی ٹیرف چنتے ہیں ، اس کے بعد آپ اسے تبدیل نہیں کر سکتے ہیں۔',
      TARIFFS_INFO: 'تمام جمع کرنے والے ٹوکنز ختم ہوجائیں گے۔',
      TARIFFS_DISABLED_BIG: 'ٹیرف 4 اور ٹیرف 5 بعد میں دستیاب ہوں گے ، فنڈ کی حجم کی تشکیل کے بعد۔',
      TARIFFS_DISABLED_SMALL: "2 ماہ، 1 سال، اور 2 سال کے لیے ٹیرف بعد میں دستیاب ہوں گے، فنڈ کی مقدار کا حساب لگانے کے بعد۔",
      SELECT_TARIFF: 'تاریخچہ منتخب کریں',
      CURRENT_TARIFF: 'آپ کا تاریخچہ',
      DEFAULT: 'پہلے سے طے شدہ',
      USER_WILL_RECEIVE: 'آپ کو مدت کے آخر تک ملے گا:',
      FUND_WILL_RECEIVE: 'آپ کی ادائیگی فنڈ میں:',
      FUND_RECEIVED: 'فنڈ میں:',
      FUNDING_AMOUNT: 'فنڈ کا حجم',
      BURN: '15٪ جلا دیا جائے گا',
      BUYBACK_INFO: "اگر آپ میسنجر سے سکے حاصل کرنے کا انتظار نہیں کرنا چاہتے، تو آپ اضافی شرائط کے بارے میں جان سکتے ہیں",
      BUYBACK_LINK: "یہاں کلک کرکے",
    },
  },
};
