export const locale = {
  lang: 'en',
  data: {
    VESTING_TARIFFS: {
      TITLE: 'Select Tariff',
      BALANCES: 'Your Balance',
      NO_VESTING_BALANCE_INFO: 'You do not have a vesting balance in your account. Accumulation is not available without a balance.',
      TARIFF_1_NAME: '2 Month Vesting Tariff',
      TARIFF_2_NAME: '1 Year Vesting Tariff',
      TARIFF_3_NAME: '2 Year Vesting Tariff',
      TARIFF_4_NAME: '3 Year Vesting Tariff',
      TARIFF_5_NAME: '4 Year Vesting Tariff',
      TARIFF_6_NAME: '5 Year Vesting Tariff',
      DURATION: 'Duration: ',
      TARIFF_1_DURATION: '2 months',
      TARIFF_2_DURATION: '1 year',
      TARIFF_3_DURATION: '2 years',
      TARIFF_4_DURATION: '3 years',
      TARIFF_5_DURATION: '4 years',
      TARIFF_6_DURATION: '5 years',
      TARIFF_1_DESCRIPTION: 'Choosing this tariff will result in a funding fee of 50% of the remaining* balance of your total HC vesting. A total of 50% HC will remain available.',
      TARIFF_2_DESCRIPTION: 'By choosing this tariff, a payment of 30% of the remaining* balance of your HC vesting will be taken as funding. The remaining 70% HC will be available.',
      TARIFF_3_DESCRIPTION: 'By choosing this tariff, a payment of 15% of the remaining* balance of your HC vesting will be taken as funding. The remaining 85% HC will be available.',
      TARIFF_4_DESCRIPTION: 'Standard plan. It does not require any expenses and will not bring additional HC.',
      TARIFF_5_DESCRIPTION: 'Allows you to keep your current HC vesting balance and additionally divide 30% from the funding pool.',
      TARIFF_6_DESCRIPTION: 'Allows you to keep your current HC vesting balance and additionally divide 70% from the funding pool.',
      TARIFFS_COMMON_INFO: 'HC vesting consists of two stages. The first stage is the release of 10% of each user\'s balance, with additional HC release for tier extension in the first month. The second stage of vesting is for the remaining balance of 90% or less if there was participation in the HC release for tier extension. The choice of vesting tariff plan applies only to stage two. The 3-year vesting tariff plan is activated by default, so if you do not intend to change it, you do not need to select anything on this page.',
      TARIFFS_COMMON_INFO_WARNING: 'The tariff selection is made once. It will not be possible to change the tariff a second time. Example: the default tariff for 3 years is currently selected. You choose the tariff for 4 years, after which you will not be able to change it.',
      TARIFFS_INFO: 'All accumulated tokens will be issued by the end of the term.',
      TARIFFS_DISABLED_BIG: 'Tariff 4 and Tariff 5 will be available later, after the fund volumes are calculated.',
      TARIFFS_DISABLED_SMALL: "Tariffs for 2 months, 1 year, and 2 years will be available later, after the fund volume calculations.",
      SELECT_TARIFF: 'Select Tariff',
      CURRENT_TARIFF: 'Your Tariff',
      DEFAULT: 'default',
      USER_WILL_RECEIVE: 'You will receive by the end of the period:',
      FUND_WILL_RECEIVE: 'Your payment to the funding pool:',
      FUND_RECEIVED: 'In the pool:',
      FUNDING_AMOUNT: 'Funding pool size',
      BURN: '15% will be burned',
      BUYBACK_INFO: "If you don't want to wait for coins from the messenger, you can familiarize yourself with the additional terms",
      BUYBACK_LINK: "by clicking here",
    },
  },
};
