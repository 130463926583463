export const locale = {
  lang: 'zh',
  data: {
    TREE: {
      FORCE_ID: 'Holiverse ID',
      HIDE: '隐藏',
      SHOW_REFERRALS: '显示推荐人',
      UPLOAD: '上传5个以上的推荐人',
      WALLET: 'Wallet:',
      UV_TIER: 'UV 等级:',
      PROFIT: '利润:',
    },
  },
};
