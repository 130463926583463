<div class="h-100 d-flex align-items-center">
  <div class="row w-100 m-0">
    <div class="col-12 header d-flex justify-content-center text-center">
      {{ 'PURCHASE.ACTIVATE' | translate }} {{ currentLevel }},
      {{ 'PURCHASE.PURCHASE' | translate }} {{ buyLevel }}
    </div>
    <div class="col-12 description d-flex justify-content-center">
      {{ 'PURCHASE.ACCESS' | translate }}
    </div>
  </div>
</div>
