export const locale = {
  lang: 'hi',
  data: {
    TIME: {
      ACTIVE_PERIOD: 'सक्रिय अवधि समाप्त हो गई है',
      ACCESS_GIVEN: 'पहुँच 30 दिनों के लिए दी गई है',
      EXTAND: 'बढ़ाएँ',
    },
  },
};
