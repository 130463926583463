export const locale = {
  lang: 'ar',
  data: {
    ACTIVITY: {
      ID: 'من معرّف',
      NEW_REFERRAL: 'إحالة جديدة',
      TIER: 'الطبقة ',
      ACTIVATION: 'تفعيل',
      EXTENSION: 'تمديد',
      GETTING_FORCECOIN: 'الحصول على فورسكوين',
      DELTA_ACTIVATION: 'فارق التفعيل',
      LOST_PROFIT: 'الأرباح المفقودة',
      YOU: 'أنت',
    },
  },
};
