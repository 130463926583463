export const locale = {
  lang: 'ru',
  data: {
    LEVEL_ITEM: {
      TIER: 'Тьир',
      ACTIVE_UNTIL: 'Активен до',
      ACTIVATE: 'Активировать',
      EXTENSION_TIME: 'Время продления',
      EXTEND_TIME: 'Продлить',
      DELTA: 'Дельта',
      BADGE_READY: 'Готово',
      BADGE_EXTENSION: 'Продление',
      BADGE_ACTIVE: 'Активно',
      BADGE_NO_ACTIVE: 'Неактивно',
      OR: 'или',
      CANT_EXTEND: "ТЬИР АКТИВИРОВАН",
      EXTEND_TEXT: 'продление через:',
    },
  },
};
