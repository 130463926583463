export const locale = {
  lang: 'hi',
  data: {
    TABS: {
      ALL: 'सभी',
      ACTIVE: 'सक्रिय',
      COMPLETED: 'पूर्ण',
    }
  },
};
