export const locale = {
  lang: 'id',
  data: {
    PURCHASE: {
      ACTIVATE: 'To activate tier',
      PURCHASE: 'purchase tier',
      ACCESS: 'Access is given for 30 days',
    },
  },
};
