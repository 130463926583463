export const locale = {
  lang: 'ru',
  data: {
    SIDE_NAV_SWAP_HMFS_ON_MFS: {
      BACK: 'Назад',
      MAX: 'Макс',
      SWAP_HMFS_MFS:"Обмен hHC на HC",
      SWAP:"Обменять",
      BALANCE: 'БАЛАНС:',
    },
  },
};
