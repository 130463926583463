export const locale = {
  lang: 'hi',
  data: {
    PURCHASE: {
      ACTIVATE: 'तहत सक्रिय करने के लिए',
      PURCHASE: 'तहत खरीदें',
      ACCESS: 'पहुँच 30 दिनों के लिए दी जाती है',
    },
  },
};
