export const locale = {
  lang: 'fr',
  data: {
    UPGRADE_WAVE_MODAL: {
      UPGRADE: 'Mettre à niveau',
      FILL: 'Remplir',
      FILL_INFO: 'Utilisez vos jetons NTC pour remplir les puces dans le traitement de votre minerai',
      LEARN_MORE: 'En savoir plus',
      YOU_HAVE: "Disponible",
      SELECT: 'Sélectionner',
      SET_MAX: 'Définir le maximum',
      YOU_GET: "Vous obtiendrez",
      BUY: 'Acheter',
      AVAILABLE: 'Disponible',
      OF: 'de',
      ERROR: 'Vous n\'avez pas assez de NTC',
      UPGRADE_BUTTON: 'Mettre à niveau sur X1',
    }
  },
};
