export const locale = {
  lang: 'zh',
  data: {
    PURCHASE: {
      ACTIVATE: '激活等级',
      PURCHASE: '购买等级',
      ACCESS: '访问权限给予30天',
    },
  },
};
