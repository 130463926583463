export const locale = {
  lang: 'ru',
  data: {
    TREE: {
      FORCE_ID: 'Holiverse ID',
      HIDE: 'Скрыть',
      SHOW_REFERRALS: 'Показать рефералов',
      UPLOAD: 'Загрузить еще 5 рефералов',
      WALLET: 'Wallet:',
      UV_TIER: 'Уровень UV:',
      PROFIT: 'Прибыль:',
    },
  },
};
