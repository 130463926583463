export const locale = {
  lang: 'zh',
  data: {
    FILTER_ORBIT: {
      LEVEL: '层级：',
      ALL: '全部',
      SHOW_LEVEL_LIST: '层级列表',
    },
  },
};
