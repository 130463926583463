export const locale = {
  lang: 'vi',
  data: {
    LINKS_PANEL: {
      DOWNLOAD_TITLE: "Uniteverse GameAf",
      DOWNLOAD_BUTTON: "Tải xuống PDF",
      LORE_LINK: "https://cdn.meta-force.space/uniteverse/eng.pdf",
      LANDING_BUTTON: "Legends of Uniteverse",
      LANDING_LINK: "/pages/lore",
    },
  },
};
