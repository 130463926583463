export const locale = {
  lang: 'ru',
  data: {
    MARKETING_TABEL: {
      FORCE_ID: 'Holiverse ID',
      ORBIT: 'Орбита',
      WALLET: 'Кошелек:',
      UV_TIER: 'Уровень UV:',
      PROFIT: 'Прибыль:',
      LINE: 'Линия',
    },
  },
};
