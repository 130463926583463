export const locale = {
  lang: 'en',
  data: {
    EXTEND_CHOOSE_TYPE_MODAL: {
      TIER: "Tier",
      ACTIVATION: "Activation",
      YOU_WILL_GET: "You will get",
      ACTIVATE_CHIP: "Activation chip",
      NEW: "new",
      CHIP_DESCRIPTION: "Chip for Activating the Quantum Wave Start Machine.",
      AMOUNT_CHIPS: "amount of chips",
      ACCESS_KEY: "Access key",
      KEY_DESCRIPTION: "Extend access time.",
      KEY_DESCRIPTION_ACTIVATION: "The access key provides entry to tiers equipped with machines that allow you to participate in quantum wave launch processes, as well as to process ore and mine Bitforce.",
      AMOUNT_DAYS: "amount of days",
      BUNDLE_SIZE: "Bundle size",
      SELECT_PAYMENT: "Select a payment method",
      PRICE: "Price",
      BALANCE: "Balance",
      SUPPORTED: "Supported by HoliDex",
      TOTAL: "Total",
      ERROR_MSG: "There are not enough funds on the balance sheet",
      ACTIVATE: "Activate",
      EXTEND_TIME: "Extend time",
      SPECIAL_PRICE: "SPECIAL PRICE",
      BUYING: "Buying from HoliDex",
      HOLDING_BY:"HOLDING by",
      TYPE:"Type",
      OPTIONS:"Options",
      DAYS_LEFT:"Days left",
      HOLDING:"Holding",
      CAN_USED_ONCE_DURING_PROMOTION_PERIOD:"Can be used once during the promotion period",
      INSUFFICIENT_BALANCE:"Insufficient balance",
      MFS_VESTING:"HC VESTING",
      TIME:"time",
      EXTEND:"Extend",
      SLIPPAGE_TOLERANCE:"SLIPPAGE TOLERANCE: ",
      LEVEL_ALREADY_BEEN_EXTENDED :"The level has already been</br> extended",
      TIMES_HMFS:"times by hHC",
      YOU_DONT_HAVE_RIGHT_TYPE_HMFS:"You don't have the right type of hHC. You can get it using HC holding or an exchange",
      STOCK_INFO1: 'The price of HC is taken from the HoliDex exchange. This means that the cost of 1 HC in DAI can fluctuate constantly.',
      STOCK_INFO2: 'We cannot guarantee that the price displayed on this screen will be the same when creating a transaction.',
      RENEWAL_FULL_PRICE:"Renewal at full price",
      RENEWAL_DISCOUNTED_PRICE:"Renewal at a discounted price",
      MFS_BY_DAI_exchange:"HC for DAI via exchange",
      EXTENDED_7_OR_MORE_TIMES:'Extended 7 or more times',
      YOU_HAVE_LEFT: 'chips remaining',
      CHIPS_LEFT: 'Chips left',
      COMPLETED_EXTENDED:'completed extended',
      FOR_THE_NEXT_EXTENDED_YOU_NEED:'for the next extended you need',
      OR_MORE_TIMES:'or more times',
    },
  },
};
