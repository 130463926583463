export const locale = {
  lang: 'ru',
  data: {
    EXTEND_CHOOSE_TYPE_MODAL: {
      TIER: "Тьир",
      ACTIVATION: "Активация",
      YOU_WILL_GET: "Вы получите",
      ACTIVATE_CHIP: "Чип активации",
      NEW: "новый",
      CHIP_DESCRIPTION: "Чип для активации машины запуска квантовой волны.",
      AMOUNT_CHIPS: "количество чипов",
      ACCESS_KEY: "Ключ доступа",
      KEY_DESCRIPTION: "Продлите время доступа.",
      KEY_DESCRIPTION_ACTIVATION: "Ключ доступа предоставляет вход на уровни, оснащенные машинами, которые позволяют участвовать в процессах запуска квантовых волн, а также обрабатывать руду и добывать Bitforce.",
      AMOUNT_DAYS: "количество дней",
      BUNDLE_SIZE: "Размер пакета",
      SELECT_PAYMENT: "Выберите способ оплаты",
      PRICE: "Цена",
      BALANCE: "Баланс",
      SUPPORTED: "Поддерживается HoliDex",
      TOTAL: "Итого",
      ERROR_MSG: "На балансе недостаточно средств",
      ACTIVATE: "Активировать",
      EXTEND_TIME: "Продлить время",
      SPECIAL_PRICE: "СПЕЦИАЛЬНАЯ ЦЕНА",
      BUYING: "Покупка через HoliDex",
      HOLDING_BY:"Холдинг используя",
      TYPE:"Тип",
      OPTIONS:"ДНЕЙ:",
      DAYS_LEFT:", ДО ЗАВЕРШЕНИЯ АКЦИИ",
      HOLDING:"Холдинг",
      CAN_USED_ONCE_DURING_PROMOTION_PERIOD:"Можно использовать один раз в период действия акции",
      INSUFFICIENT_BALANCE:"Недостаточный баланс",
      MFS_VESTING:"HC ВЕСТИНГ",
      TIME:"время",
      EXTEND:"ПРОДЛЕНИЕ",
      SLIPPAGE_TOLERANCE:"ДОПУСК ПРОСКАЛЬЗЫВАНИЯ: ",
      LEVEL_ALREADY_BEEN_EXTENDED :"Уровень уже</br> продлён",
      TIMES_HMFS:"раз за hHC",
      YOU_DONT_HAVE_RIGHT_TYPE_HMFS:"У вас нет подходящего типа hHC. Вы можете получить их с помощью HC holding или обмена",
      STOCK_INFO1: 'Цена HC берется из биржи HoliDex. Это значит что стоимость 1 HC в DAI меняется постоянно.',
      STOCK_INFO2: 'Мы не можем гарантировать, что стоимость тьира при создании транзакции будет такой же как на данном экране.',
      RENEWAL_FULL_PRICE:"Продление по полной цене",
      RENEWAL_DISCOUNTED_PRICE:"Продление по дисконтной цене",
      MFS_BY_DAI_exchange:"HC за DAI через биржу",
      EXTENDED_7_OR_MORE_TIMES:'Продлено 7 или более раз',
      YOU_HAVE_LEFT: "осталось чипсов",
      CHIPS_LEFT: "Осталось чипов",
      COMPLETED_EXTENDED:'завершено',
      FOR_THE_NEXT_EXTENDED_YOU_NEED:'для следующего расширенного вам нужно',
      OR_MORE_TIMES:'или более раз',
    },
  },
};
