export const locale = {
  lang: 'vi',
  data: {
    SIDE_NAV_SWAP_HMFS_ON_MFS: {
      BACK: 'Quay lại',
      MAX: 'Tối đa',
      SWAP_HMFS_MFS:"Trao Đổi hHC cho HC",
      SWAP:"Trao đổi",
      BALANCE: 'SỰ CÂN BẰNG:',
    },
  },
};
