export const locale = {
  lang: 'en',
  data: {
    LINKS_PANEL: {
      DOWNLOAD_TITLE: "Uniteverse GameAf",
      DOWNLOAD_BUTTON: "Download PDF",
      LORE_LINK: "https://cdn.meta-force.space/uniteverse/eng.pdf",
      LANDING_BUTTON: "Legends of Uniteverse",
      LANDING_LINK: "/pages/lore",
    },
  },
};
