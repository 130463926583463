export const locale = {
  lang: 'zh',
  data: {
    ACTIVITY: {
      ID: '來自ID',
      NEW_REFERRAL: '新介紹人',
      TIER: '層級',
      ACTIVATION: '啟用',
      EXTENSION: '延長',
      GETTING_FORCECOIN: '獲取Holichain',
      DELTA_ACTIVATION: 'Delta啟用',
      LOST_PROFIT: '失去的利潤',
      YOU: '你',
    },
  },
};
