export const locale = {
  lang: 'fr',
  data: {
    TABS: {
      ALL: 'Tout',
      ACTIVE: 'Actif',
      COMPLETED: 'Terminé',
    }
  },
};
