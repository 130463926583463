export const locale = {
  lang: 'ar',
  data: {
    TABS: {
      ALL: 'الكل',
      ACTIVE: 'نشط',
      COMPLETED: 'مكتمل',
    }
  },
};
