export const locale = {
  lang: 'hi',
  data: {
    ACTIVITY: {
      ID: 'आईडी से',
      NEW_REFERRAL: 'नया संदर्भ',
      TIER: 'टियर',
      ACTIVATION: 'सक्रियण',
      EXTENSION: 'विस्तार',
      GETTING_FORCECOIN: 'फोर्सकॉइन प्राप्त करना',
      DELTA_ACTIVATION: 'डेल्टा सक्रियण',
      LOST_PROFIT: 'खोई हुई लाभ',
      YOU: 'तुम',
    },
  },
};
