export const locale = {
  lang: 'vi',
  data: {
    FILTER_ORBIT: {
      LEVEL: 'Hạng:',
      ALL: 'Delta',
      SHOW_LEVEL_LIST: 'Danh sách hạng',
    },
  },
};
