export const locale = {
  lang: 'zh',
  data: {
    SIDE_NAV_SWAP_HMFS_ON_MFS: {
      BACK: '後退',
      MAX: '最大限度',
      SWAP_HMFS_MFS:"將 hHC 交換為 HC",
      SWAP:"交換",
      BALANCE: '平衡：',
    },
  },
};
