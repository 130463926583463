export const locale = {
  lang: 'zh',
  data: {
    TABS: {
      ALL: '全部',
      ACTIVE: '活动',
      COMPLETED: '已完成',
    }
  },
};
