export const locale = {
  lang: 'zh',
  data: {
    BREADCRUMB: {
      FORCE_SYSTEMS: 'Force 系统',
      UNITEVERSE_DELTA: 'Uniteverse Delta',
      FORCECOIN: 'Holichain（力量币）',
      MATRIX: 'GameAf',
      RECENT_ACTIVITY: '最近活动',
      LORE: 'Legends of Uniteverse',
      BACK: 'Back',
      OVERVIEW: '矿山',
      TIERS: '层级',
      PROCESSING: '波浪',
    },
  },
};
