export const locale = {
  lang: 'ar',
  data: {
    CONFIRM_MODAL: {
      TITLE: 'تنبيه',
      SUBTITLE: 'تنبيه',
      TARIFF_5_INFO: "عند اختيار هذه التعريفة، سيتم تحصيل رسوم تمويل بنسبة 50% من الرصيد الإجمالي المتبقي في حساب HC الخاص بك.",
      TARIFF_1_INFO: 'عند اختيار هذا الخطة، سيتم خصم 30٪ من رصيدك الإجمالي المتبقي في HC.',
      TARIFF_2_INFO: 'عند اختيار هذا الخطة، سيتم خصم 15٪ من رصيدك الإجمالي المتبقي في HC.',
      TARIFF_0_INFO: 'أنت تختار خطة لمدة ثلاث سنوات.',
      TARIFF_3_INFO: 'أنت تختار خطة لمدة أربع سنوات.',
      TARIFF_4_INFO: 'أنت تختار خطة لمدة خمس سنوات.',
      TARIFF_AGREEMENT: 'أؤكد أنني قرأت وفهمت جميع شروط اختيار الخطة والتمويل. أوافق على هذه الشروط.',
      INFO: 'يتم اختيار الخطة مرة واحدة فقط. لا يمكن تغيير الخطة بعد هذا الاختيار.',
      ARE_YOU_SURE: 'هل ترغب في تطبيق خطة جديدة؟',
      YES: 'نعم',
      NO: 'لا',
    },
  },
};
