<div class="row h-100 m-0">
  <div
    class="col-12 d-flex align-items-center justify-content-between header-menu">
    <div
      class="back-arrow-wallet d-flex align-items-center cursor-pointer"
      (click)="closeSlideNavAction(false)">
      <div class="icon"></div>
      {{ 'SIDE_NAV_WALLET_ACTION.BACK' | translate }}
    </div>
    <div class="header-title">
      {{ 'SIDE_NAV_WALLET_ACTION.TRANSFER' | translate }}
    </div>
    <img
      class="button-close cursor-pointer button-black"
      (click)="closeALLSlideNav(false)"
      src="/assets/icon/closeButton.svg"
      alt="" />
  </div>

  <div class="col-12 height-container-menu">
    <div class="row m-0">
      <div class="col-12 p-0 container-gif d-flex justify-content-center">
        <ng-lottie
          class="gif"
          [options]="options"
          (complete)="complete($event)"
          (animationCreated)="animationCreated($event)"></ng-lottie>
      </div>
      <div class="col-12 transfer-container">
        <div class="container-from">
          <div class="small-text text-grey margin-bottom-8">
            {{ 'SIDE_NAV_WALLET_ACTION.FROM' | translate }}
          </div>
          <div class="big-text text-white">
            {{
              transferIsInner
                ? ('SIDE_NAV_WALLET_ACTION.INNER_BALANCE' | translate)
                : ('SIDE_NAV_WALLET_ACTION.WALLET_BALANCE' | translate)
            }}
          </div>
        </div>
        <div class="container-to">
          <div class="small-text text-grey margin-bottom-8">
            {{ 'SIDE_NAV_WALLET_ACTION.TO' | translate }}
          </div>
          <div class="big-text text-white">
            {{
              transferIsInner
                ? ('SIDE_NAV_WALLET_ACTION.WALLET_BALANCE' | translate)
                : ('SIDE_NAV_WALLET_ACTION.INNER_BALANCE' | translate)
            }}
          </div>
        </div>
        <div
          class="transfer-change-img"
          (click)="changeTransfer()"
          (mouseenter)="over()"
          (mouseleave)="out()">
          <img
            [@hover]="stateUp"
            (@hover.done)="onDone($event)"
            class=""
            src="/assets/icon/up-white.png"
            alt="" />

          <img
            [@hover]="stateDown"
            (@hover.done)="onDone($event)"
            src="/assets/icon/down-white.png"
            alt="" />
        </div>
      </div>
      <div class="col-12 number-container">
        <div class="row m-0">
          <div class="col-6 p-0">
            <div class="small-text text-grey margin-bottom-8">
              {{ 'SIDE_NAV_WALLET_ACTION.NUMBER' | translate }}
            </div>
            <input
              type="number"
              inputmode="numeric"
              step="0.01"
              pattern="[0-9]*"
              class="text-grey text-number-size margin-bottom-8 w-100"
              [ngModel]="amountTransaction"
              aria-label="Amount"
              placeholder="0"
              (ngModelChange)="changeTotal(+$event)" />
            <div class="small-text">
              <span class="text-grey">
                {{ 'SIDE_NAV_WALLET_ACTION.BALANCE' | translate }}:</span
              >
              <span class="text-white">
                {{
                  +(transferIsInner ? +item.valuePayment : +item.value).toFixed(
                    1
                  )
                }}
              </span>
            </div>
          </div>
          <div class="col-6 p-0 d-flex justify-content-end align-items-center">
            <button class="button-grey button-max" (click)="setMax()">
              {{ 'SIDE_NAV_WALLET_ACTION.MAX' | translate }}
            </button>
            <!-- <span class=" conteiner-coning-soon">
                        
                            <div class="coning-soon coning-soon-top">
                                {{ 'SIDE_NAV_WALLET_VALUE.CONING_SOON' | translate }}
                            </div> -->

            <a class="select-coin d-flex align-items-center text-white">
              <div class="margin-right-8px">
                <app-crypto-tokens
                  [width]="32"
                  [height]="32"
                  [imgName]="item.img">
                </app-crypto-tokens>
              </div>
              <span class="big-text">
                {{ item.name === 'MFS' ? 'HC' : item.name }}
              </span>
            </a>
            <!-- </span> -->
          </div>
        </div>
      </div>
      <div class="col-12 p-0">
        <button
          [disabled]="isLoading"
          class="btn-orange w-100"
          (click)="transfer(item.token)">
          <span
            *ngIf="isLoading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"></span>
          {{ 'SIDE_NAV_WALLET_ACTION.TRANSFER_NOW' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
