export const locale = {
  lang: 'zh',
  data: {
    TIME: {
      ACTIVE_PERIOD: '活动期已结束',
      ACCESS_GIVEN: '访问权限为30天',
      EXTAND: '延长',
    },
  },
};
