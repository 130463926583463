export const locale = {
  lang: 'hi',
  data: {
    CONFIRM_MODAL: {
      TITLE: 'ध्यान दें',
      SUBTITLE: 'ध्यान दें',
      TARIFF_5_INFO: "जब आप इस टैरिफ का चयन करते हैं, तो आपके HC वेस्टिंग खाते की शेष राशि से 50% फंडिंग शुल्क लिया जाएगा।",
      TARIFF_1_INFO: 'इस टैरिफ का चयन करने पर आपके बचे हुए HC वेस्टिंग के कुल शेष बैलेंस का 30% फंडिंग का भुगतान लिया जाएगा*।',
      TARIFF_2_INFO: 'इस टैरिफ का चयन करने पर आपके बचे हुए HC वेस्टिंग के कुल शेष बैलेंस का 15% फंडिंग का भुगतान लिया जाएगा*।',
      TARIFF_0_INFO: 'आप तीन साल की योजना चुन रहे हैं।',
      TARIFF_3_INFO: 'आप चार साल की योजना चुन रहे हैं।',
      TARIFF_4_INFO: 'आप पांच साल की योजना चुन रहे हैं।',
      TARIFF_AGREEMENT: 'मैं पुष्टि करता हूँ कि मैंने टैरिफ प्लान और फंडिंग के सभी शर्तों को ध्यान से पढ़ा है। मैं इन शर्तों से सहमत हूँ।',
      INFO: 'टैरिफ का चयन एक बार किया जाता है। इस चयन के बाद टैरिफ का चयन बदलना असंभव होगा।',
      ARE_YOU_SURE: 'क्या आप नया टैरिफ प्लान लागू करना चाहते हैं?',
      YES: 'हाँ',
      NO: 'नहीं',
    },
  },
};
