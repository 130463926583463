<div class="h-100 d-flex align-items-center">
  <div class="row w-100 m-0">
    <div class="col-12 header d-flex justify-content-center">
      {{ 'TIME.ACTIVE_PERIOD' | translate }}
    </div>
    <div class="col-12 description d-flex justify-content-center">
      {{ 'TIME.ACCESS_GIVEN' | translate }}
    </div>
    <div class="col-12 value d-flex justify-content-center">
      200 DAI | 800 HC
    </div>
    <div class="col-12 d-flex justify-content-center">
      <button type="button" class="btn btn-warning size-button" rippleEffect>
        {{ 'TIME.EXTAND' | translate }}
      </button>
    </div>
  </div>
</div>
