export const locale = {
  lang: 'ar',
  data: {
    MARKETING: {
      NEW: 'جديد',
      OVERTAKING: '⬤ التفوق',
      DESCRIPTION: 'الوصف الوصفي للوصف الموصوف للوصف الموصوف للوصف',
    },
  },
};
