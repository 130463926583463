export const locale = {
  lang: 'vi',
  data: {
    LEVEL_ITEM: {
      TIER: 'Cấp',
      ACTIVE_UNTIL: 'Hoạt động đến',
      ACTIVATE: 'Kích hoạt',
      EXTENSION_TIME: 'Thời gian gia hạn',
      EXTEND_TIME: 'Gia hạn',
      DELTA: 'Delta',
      BADGE_READY: 'Sẵn sàng',
      BADGE_EXTENSION: 'Gia hạn',
      BADGE_ACTIVE: 'Hoạt động',
      BADGE_NO_ACTIVE: 'Không hoạt động',
      OR: 'hoặc',
      CANT_EXTEND: "ĐÃ KÍCH HOẠT",
      EXTEND_TEXT: 'gia hạn sau:',
    },
  },
};
