export const locale = {
  lang: 'ur',
  data: {
    SIDE_NAV_SERVICES: {
      ALL_SERVICES: 'تمام خدمات',
      NEWS_EVENTS: 'خبروں اور واقعات',
      DESCRIPTION_NEWS_EVENTS: 'خبروں اور واقعات کا پورٹل',
      FORCE_SYSTEMS: 'فورس سسٹمز',
      DESCRIPTION_FORCE_SYSTEMS: 'ٹیکٹائل / یونیورس / رائلٹی این ایف ٹی',
      CONTESTS: 'مقابلے',
      ACADEMY: 'اکیڈمی',
      NFTS_MARKETPLACE: 'این ایف ٹیس مارکیٹ پلیس',
      FORCEDEX: 'HoliDex',
      METAVERCE: 'میٹاورس',
      COMING_SOON: 'جلد ہی آ رہا ہے',
      PROMOTION: 'تشہیر',
      MANAGEMENT_LINKS: 'انتظامی لنکس',
      HOME: 'ہوم',
      OTHER: 'دیگر',
    },
  },
};
