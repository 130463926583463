export const locale = {
  lang: 'fr',
  data: {
    BREADCRUMB: {
      FORCE_SYSTEMS: 'Holiverse system',
      UNITEVERSE_DELTA: 'Uniteverse Delta',
      FORCECOIN: 'Holichain',
      MATRIX: 'GameAf',
      RECENT_ACTIVITY: 'Transactions récentes',
      LORE: 'Légendes de l’Uniteverse',
      BACK: 'Retour',
      OVERVIEW: 'Mine',
      TIERS: 'Niveaux',
      PROCESSING: 'Vagues'
    },
  },
};
