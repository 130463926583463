export const locale = {
  lang: 'ar',
  data: {
    VESTING_TARIFFS: {
      TITLE: 'اختر الرسوم',
      BALANCES: 'رصيدك',
      NO_VESTING_BALANCE_INFO: 'ليس لديك رصيد تراكمي في حسابك. التراكم غير متاح بدون رصيد.',
      TARIFF_1_NAME: 'التعريفة لمدة شهرين',
      TARIFF_2_NAME: 'رسوم التراكم لمدة سنة واحدة',
      TARIFF_3_NAME: 'رسوم التراكم لمدة سنتين',
      TARIFF_4_NAME: 'رسوم التراكم لمدة 3 سنوات',
      TARIFF_5_NAME: 'رسوم التراكم لمدة 4 سنوات',
      TARIFF_6_NAME: 'رسوم التراكم لمدة 5 سنوات',
      DURATION: 'المدة: ',
      TARIFF_1_DURATION: 'شهرين',
      TARIFF_2_DURATION: 'سنة واحدة',
      TARIFF_3_DURATION: 'سنتين',
      TARIFF_4_DURATION: '3 سنوات',
      TARIFF_5_DURATION: '4 سنوات',
      TARIFF_6_DURATION: '5 سنوات',
      TARIFF_1_DESCRIPTION: 'إذا اخترت هذه التعريفة، سيتم استخدام 50٪ من رصيد حساب HC المؤجل كرسوم تمويل. وسيكون 50٪ من إجمالي HC متاحًا.',
      TARIFF_2_DESCRIPTION: 'عند اختيار هذه الرسوم، سيتم خصم 30٪ من رصيد التراكم الإجمالي لديك في HC كدفعة تمويلية. ستظل 70٪ من HC متاحة.',
      TARIFF_3_DESCRIPTION: 'عند اختيار هذه الرسوم، سيتم خصم 15٪ من رصيد التراكم الإجمالي لديك في HC كدفعة تمويلية. ستظل 85٪ من HC متاحة.',
      TARIFF_4_DESCRIPTION: 'خطة قياسية. لا تتطلب تكاليف ولا توفر HC إضافية.',
      TARIFF_5_DESCRIPTION: 'سيسمح لك بالاحتفاظ برصيد التراكم الحالي في HC وتقسيم 30٪ إضافي من صندوق التمويل.',
      TARIFF_6_DESCRIPTION: 'سيسمح لك بالاحتفاظ برصيد التراكم الحالي في HC وتقسيم 70٪ إضافي من صندوق التمويل.',
      TARIFFS_COMMON_INFO: 'يتكون تراكم HC من مرحلتين. المرحلة الأولى هي تحرير 10٪ من رصيد كل مستخدم، مع تحرير إضافي لـ HC لتمديد المستويات في الشهر الأول. المرحلة الثانية من التراكم هي تحرير الأرصدة المتبقية بنسبة 90٪ أو أقل إذا تم المشاركة في تحرير HC لتمديد المستويات. يتم تطبيق اختيار خطة الرسوم التراكم فقط على المرحلة الثانية. تم تفعيل خطة الرسوم التراكم لمدة 3 سنوات بشكل افتراضي، إذا كنت لا تنوي تغييرها فليس عليك اختيار أي شيء في هذه الصفحة.',
      TARIFFS_COMMON_INFO_WARNING: 'يتم اختيار الرسوم مرة واحدة فقط. لن يكون من الممكن تغيير الرسوم مرة أخرى. مثال: حاليًا تم اختيار رسوم لمدة 3 سنوات بشكل افتراضي. تختار رسوم لمدة 4 سنوات، بعد ذلك لن تتمكن من تغييرها.',
      TARIFFS_INFO: 'سيتم إصدار جميع الرموز التراكمية قبل نهاية المدة.',
      TARIFFS_DISABLED_BIG: 'سيتم توفير رسوم 4 ورسوم 5 في وقت لاحق، بعد حساب حجم الصندوق.',
      TARIFFS_DISABLED_SMALL: "التعريفات لمدة شهرين، وسنة، وسنتين ستكون متاحة لاحقًا، بعد حساب حجم الصندوق.",
      SELECT_TARIFF: 'اختر الرسوم',
      CURRENT_TARIFF: 'رسومك الحالية',
      DEFAULT: 'افتراضي',
      USER_WILL_RECEIVE: 'ستحصل على:',
      FUND_WILL_RECEIVE: 'دفعتك إلى صندوق التمويل:',
      FUND_RECEIVED: 'في الصندوق:',
      FUNDING_AMOUNT: 'حجم التمويل',
      BURN: 'سيتم حرق 15٪',
      BUYBACK_INFO: "إذا كنت لا ترغب في الانتظار للحصول على العملات من الرسول، يمكنك التعرف على الشروط الإضافية",
      BUYBACK_LINK: "بالنقر هنا",
    },
  },
};
