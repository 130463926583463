export const locale = {
  lang: 'ar',
  data: {
    CLAIM_MODAL: {
      CLAIM: "استدعاء",
      TITLE: "تهانينا على الربح الجديد",
      SUBTITLE: "تم استخراج المعادن، يمكنك الآن طبع مكافأتك",
      REWARD: "مكافأتك",
    }
  },
};
