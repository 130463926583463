export const locale = {
  lang: 'hi',
  data: {
    SIDE_NAV_HOLD_MFS_WALLET: {
      BACK: 'वापस',
      HOLD_MFS:'पकड़ो HC',
      HOLD_HMFS:'पकड़ो hHC',
      HOLD: 'पकड़ो',
      DAY: 'दिन',
      GET: 'प्राप्त करें',
      INNER_BALANCE: 'आंतरिक संतुलन',
      HMFS_TO_MFS: 'hHC से HC',
      TAKE_MFS:'ले लो HC',
      TAKE_HMFS:'ले लो hHC',
    },
  },
};
