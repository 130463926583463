export const locale = {
  lang: 'ur',
  data: {
    SIDE_NAV_HOLD_MFS_WALLET: {
      BACK: 'واپس',
      HOLD_MFS:'HC پکڑو',
      HOLD_HMFS:'hHC پکڑو',
      HOLD: 'پکڑو',
      DAY: 'دن',
      GET: 'حاصل کریں',
      INNER_BALANCE: 'اندرونی توازن',
      HMFS_TO_MFS: 'HC سے hHC',
      TAKE_MFS:'HC لے لو',
      TAKE_HMFS:'hHC لے لو',
    },
  },
};
