export const locale = {
  lang: 'ur',
  data: {
    NOT_ACTIVATED: {
      HEADER: "You haven't activated this tier yet",
      DESCRIPTION: 'Access is given for 30 days',
      OVERTAKING: '⬤ Overtaking',
      ACTIVATE: 'Activate',
    },
  },
};
