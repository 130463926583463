export const locale = {
  lang: 'id',
  data: {
    TIME: {
      ACTIVE_PERIOD: 'The active period is over',
      ACCESS_GIVEN: 'Access is given for 30 days',
      EXTAND: 'Extand',
    },
  },
};
