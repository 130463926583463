export const locale = {
  lang: 'vi',
  data: {
    MARKETING_TABEL: {
      FORCE_ID: 'ID Lực lượng',
      ORBIT: 'Quỹ vi mô',
      WALLET: 'Ví tiền:',
      UV_TIER: 'Hạng UV:',
      PROFIT: 'Lợi nhuận:',
      LINE: 'Dòng',
    },
  },
};
