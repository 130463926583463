export const locale = {
  lang: 'hi',
  data: {
    LINKS_PANEL: {
      DOWNLOAD_TITLE: "यूनिटवर्स गेम एफ",
      DOWNLOAD_BUTTON: "पीडीएफ डाउनलोड करें",
      LORE_LINK: "https://cdn.meta-force.space/uniteverse/eng.pdf",
      LANDING_BUTTON: "Legends of Uniteverse",
      LANDING_LINK: "/pages/lore",
    },
  },
};
