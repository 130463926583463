export const locale = {
  lang: 'hi',
  data: {
    FILTER_ORBIT: {
      LEVEL: 'टियर:',
      ALL: 'डेल्टा',
      SHOW_LEVEL_LIST: 'टियर सूची दिखाएं',
    },
  },
};
