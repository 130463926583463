export const locale = {
  lang: 'en',
  data: {
    CLAIM_MODAL: {
      CLAIM: 'Claim',
      TITLE: 'Congratulations on the new profit',
      SUBTITLE: 'Ore mining сomplete you can stamp your reward',
      REWARD: 'Your reward',
    },
  },
};
