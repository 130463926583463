export const locale = {
  lang: 'en',
  data: {
    SELECT_CHIP_MODAL: {
      TITLE: 'Set Chip',
      LEVEL: 'Level',
      INFO: 'You have chips',
      SELECT: 'Select chips',
      ERROR: 'You don\'t have enough chips',
      CHIPS_NOT_FOUND: 'Chips not found',
    },
  },
};
