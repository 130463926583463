export const locale = {
  lang: 'vi',
  data: {
    MARKETING: {
      NEW: 'mới',
      OVERTAKING: '⬤ Vượt qua',
      DESCRIPTION: 'Mô tả mô tả của mô tả cho mô tả mô tả để mô tả mô tả',
    },
  },
};
