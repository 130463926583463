export const locale = {
  lang: 'ur',
  data: {
    MINI_GAME_MODAL: {
      TITLE: 'توانائی جمع کرنے والا آلہ',
      START: 'ابھی شروع کریں',
      CLAIM: 'دعوی کریں',
      BALANCE: 'آپ کا بیلنس',
      PROFIT: 'آپ کا منافع',
      TIME_LEFT: 'باقی وقت',
      WAIT: 'لہر میں حصہ لیں تاکہ NTC میں انعامات حاصل کر سکیں',
      WAIT_TX: 'لین دین جاری ہے۔ ہم بہت جلد آپ کے انعام کے بارے میں معلومات کو اپ ڈیٹ کریں گے۔ آپ تفصیلات یہاں دیکھ سکتے ہیں',
      ERROR_TX: 'ایسا لگتا ہے کہ پچھلی ٹرانزیکشن کی پروسیسنگ کے دوران ایک غلطی ہوئی۔ براہ کرم دوبارہ کوشش کریں۔ آپ تفصیلات یہاں دیکھ سکتے ہیں',
      TX_LINK: 'لین دین کا لنک',
      TOOLTIP: 'ایک آلہ جو کان سے خارج ہونے والی توانائی کو جمع کرتا ہے۔ اس توانائی کو بیٹری چارج کرنے کے لیے استعمال کیا جا سکتا ہے۔\n' +
          'ہر 24 گھنٹے میں ایک کام شروع کریں اور NTC میں ایک مقررہ انعام حاصل کریں۔ پچھلا انعام جمع کرنے کے بعد ایک نیا کام خودبخود شروع ہو جائے گا۔\n',
      NO_ACTIVE_WAVES: 'آپ کے پاس کوئی فعال موجیں نہیں ہیں'
    },
  },
};
