export const locale = {
  lang: 'ar',
  data: {
    TIME: {
      ACTIVE_PERIOD: 'انتهت فترة النشاط',
      ACCESS_GIVEN: 'يمنح الوصول لمدة 30 يومًا',
      EXTAND: 'تمديد',
    },
  },
};
