export const locale = {
  lang: 'ru',
  data: {
    MINI_GAME_MODAL: {
      TITLE: 'Устройство для сбора энергии',
      START: 'Начать сейчас',
      CLAIM: 'Получить',
      BALANCE: 'Ваш баланс',
      PROFIT: 'Ваша прибыль',
      TIME_LEFT: 'Осталось времени',
      WAIT: 'Участвуй в волне, чтобы получать награды в NTC',
      WAIT_TX: 'Транзакция в процессе. Свовсем скоро мы обновим информацию о вашей награде. Вы можете посмотреть подробности по',
      ERROR_TX: 'Похоже, что произошла ошибка при выполнении последней транзакции. Пожалуйста, попробуйте еще раз. Вы можете посмотреть подробности по',
      TX_LINK: 'ссылке на транзакцию',
      TOOLTIP: 'Устройство, которое собирает энергию, излучаемую шахтой. Эту энергию можно использовать для зарядки батареи.\n' +
          'Начинайте задание каждые 24 часа и получайте фиксированное вознаграждение в NTC. Новое задание начнется автоматически после сбора предыдущего вознаграждения.\n',
      NO_ACTIVE_WAVES: 'У Вас нет ни одной активной волны'
    }
  },
};
