export const locale = {
  lang: 'ur',
  data: {
    CLAIM_MODAL: {
      CLAIM: "دعوے",
      TITLE: "نئی آمدنی پر مبارکباد",
      SUBTITLE: "خراجات کی کان کنی مکمل ہو چکی ہے، آپ اب اپنی انعام کو مہر لگا سکتے ہیں",
      REWARD: "آپ کا انعام",
    }
  },
};
