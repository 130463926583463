export const locale = {
  lang: 'ur',
  data: {
    GAME_HELP: {
      TITLE: "رہنمائی",
      STEP_0_TILE: "Uniteverse",
      STEP_0_TEXT: "جانیں یہ سب کیسے کام کرتا ہے",
      STEP_0_BUTTON: "جاری رکھیں",

      STEP_1_TITLE: "مرکزی صفحہ: یونائیٹ ورس کان کا داخلہ",
      STEP_1_TEXT: "یہ صفحہ یونائیٹ ورس کان کا داخلہ ہے جہاں آپ کلیدی معلومات دیکھ سکتے ہیں اور ان تک رسائی حاصل کر سکتے ہیں۔ یہاں آپ کی بنیادی میٹرکس اور موجودہ ترقی اور حالت کو ظاہر کرنے والی اہم حالتیں دکھائی جاتی ہیں۔",

      STEP_2_TITLE: "کل آمدنی اور بیلنس",
      STEP_2_TEXT: "اس پینل پر، آپ اپنے موجودہ توانائی کے سکوں اور وسائل کے بیلنس کی نگرانی کر سکتے ہیں۔",

      STEP_3_TITLE: "توانائی کی کان کنی",
      STEP_3_TEXT: "اس ڈیوائس کو استعمال کرتے ہوئے، آپ ہر 24 گھنٹے میں NTC (نیٹ چارج) توانائی کی کان کنی کر سکتے ہیں۔ روزانہ توانائی جمع کریں تاکہ آپ کی بیٹریاں مکمل طور پر چارج ہو سکیں۔ بیٹری چارج کرنے سے غیر صاف شدہ دھات کی مقدار بڑھ جاتی ہے جو پراسیس کی جا سکتی ہے، اس طرح آپ کی مجموعی پیداوار میں اضافہ ہوتا ہے۔",

      STEP_4_TITLE: "رسائی کی کلید اور درجات",
      STEP_4_TEXT: "درجہ رسائی کی کلید کو فعال کر کے، آپ کو درجہ تک رسائی حاصل ہوتی ہے تاکہ آپ کوانٹم ویو لانچ میں حصہ لے سکیں، جس کے بعد دھات کی پراسیسنگ اور بٹ فورس کی کان کنی ہوتی ہے۔ فعال ہونے اور رسائی میں توسیع کے بعد، آپ کو ایک چپ بھی ملتی ہے جو خودکار طور پر کوانٹم ویو لانچ ڈیوائس میں نصب ہو جاتی ہے۔ کوانٹم ویو لانچ کے بعد، آپ اس کوانٹم ویو میں نکالی گئی دھات کو پراسیس کر سکتے ہیں۔" +
          "\n\n" +
          "اسکرین کے اوپر، آپ موجودہ کوانٹم ویو لانچ کی حالت کی نگرانی کر سکتے ہیں۔ نیچے ایک سیکشن ہے جس میں کان کی کراس سیکشنل پلان ہے، جو درجات کی گہرائی کو ظاہر کرتا ہے اور آپ کی فعال درجہ رسائیوں کو دکھاتا ہے۔",

      STEP_5_TITLE: "درجات میں داخل ہوں",
      STEP_5_TEXT: "داخل ہونے کے لیے، کسی بھی فعال درجہ پر کلک کریں۔ یہاں آپ وسائل کی پراسیسنگ اور استخراج کے عمل کو منظم کر سکتے ہیں۔",

      STEP_6_TITLE: "کان کا صفحہ",
      STEP_6_TEXT: "اس صفحے پر، آپ وہ درجات دیکھ سکتے ہیں جن تک آپ کی رسائی ہے۔ ان درجات میں مختلف مشینیں موجود ہیں۔",

      STEP_7_TITLE: "کنٹرول پینل",
      STEP_7_TEXT: "اس پینل پر، آپ نگرانی کر سکتے ہیں کہ کتنی چپس موجودہ کوانٹم ویو لانچ میں شامل ہیں۔ اس کے علاوہ، یہ بھی دکھاتا ہے کہ کون سے درجات تک آپ کی رسائی ہے۔",

      STEP_8_TITLE: "درجہ کا خلا",
      STEP_8_TEXT: "ہر درجہ کا خلا اپنی منفرد مشینیں رکھتا ہے۔ یہاں آپ موجودہ درجہ تک رسائی کا وقت، کوانٹم ویو لانچ مشینوں کی تیاری کی حالت، اور گیم اے ایف نیٹ ورک کا جائزہ دیکھ سکتے ہیں۔",

      STEP_9_TITLE: "درجہ کی توسیع",
      STEP_9_TEXT: "اپنی درجہ تک رسائی کو بڑھائیں، جو ابتدائی طور پر 30 دنوں کے لیے دی جاتی ہے، تاکہ اسے استعمال کرتے رہیں۔ اگر پچھلے درجات فعال نہیں ہیں، تو تجدید صرف جرمانے کے ساتھ دستیاب ہے۔" +
          "\n\n" +
          "جب آپ درجہ کی توسیع کرتے ہیں، تو ایک چپ بھی اسی درجہ کے کوانٹم ویو لانچ کے لیے ایک ڈیوائس کو مختص کی جاتی ہے۔" +
          "\n\n" +
          "اپنے موجودہ درجہ کی فعالیت کے ختم ہونے تک باقی دن دیکھیں۔",

      STEP_10_TITLE: "درجہ رسائی کی کلید کی فعالیت",
      STEP_10_TEXT: "اس مثال میں، ہم درجہ 3 تک رسائی کو فعال کریں گے۔",

      STEP_11_TITLE: "درجہ رسائی کی کلید کی فعالیت",
      STEP_11_TEXT: "یہ ونڈو دکھاتی ہے کہ آپ کتنی چپس اور رسائی کا وقت حاصل کریں گے۔ ادائیگی HC میں کی جاتی ہے۔ اگر آپ کے اکاؤنٹ میں یہ نہیں ہے، تو آپ پہلا آپشن منتخب کر سکتے ہیں، جو مارکیٹ سے موجودہ شرح پر خودکار طور پر HC خریدے گا۔" +
          "\n\n" +
          "کئی فعالیاں یا توسیعات منتخب کرتے وقت، اس درجہ کے لیے پہلی حاصل کردہ چپ موجودہ کوانٹم ویو لانچ کے لیے مشین میں نصب کی جائے گی۔ دوسری چپ کوانٹم ویو لانچ کے 30 دن بعد استعمال کی جائے گی جب پہلی چپ کے ساتھ ویو لانچ کی جاتی ہے۔ تیسری چپ دوسری چپ کے ساتھ ویو لانچ کے 30 دن بعد فعال ہو گی، اور اسی طرح۔",

      STEP_12_TITLE: "کوانٹم خلا",
      STEP_12_TEXT: "اگلے خلا میں، دو مشترکہ مشینیں تمام درجات کے لیے دستیاب ہیں۔ پہلی مشین دھات کو کوانٹم دھات میں پراسیس کرتی ہے، جبکہ دوسری مشین بٹ فورس کی کان کنی کرتی ہے۔",

      STEP_13_TITLE: "دھات پراسیسنگ مشین",
      STEP_13_TEXT: "آئیے دیکھتے ہیں کہ دھات پراسیسنگ مشین کیسے کام کرتی ہے۔" +
          "\n\n" +
          "شروع سے: جب 88 ڈیوائسز فعال ہوتی ہیں تو ایک کوانٹم ویو شروع ہو گی۔ یہ ویو خام دھات نکالے گی۔ ویو لانچ میں شامل چپس اس ڈیوائس میں 30 دنوں کے دوران پراسیس کی جانے والی دھات کی ممکنہ زیادہ سے زیادہ مقدار کا تعین کریں گی۔ ان 30 دنوں کے بعد، آپ کے پاس کوانٹم دھات جمع کرنے کا موقع ہو گا۔" +
          "\n\n" +
          "دھات کو پراسیس کرنے کے لیے، آپ کو اپنی بیٹریاں چارج رکھنی ہوں گی۔ ڈیفالٹ کے طور پر، آپ کے پاس ایک بیٹری ہے، لیکن جلد ہی آپ کے پاس ایک اضافی بیٹری خریدنے کا آپشن ہو گا۔ اس لیے، اگر آپ دھات پراسیسنگ میں مکمل طور پر حصہ لیتے ہیں، تو آپ زیادہ سے زیادہ ممکنہ کوانٹم دھات حاصل کریں گے۔ تاہم، اگر آپ دھات پراسیسنگ میں حصہ نہیں لیتے، تو آپ کو کچھ نہیں ملے گا۔",

      STEP_14_TITLE: "بیٹری چارج کرنا",
      STEP_14_TEXT: "یہاں آپ دیکھ سکتے ہیں کہ آپ دھات کی پراسیسنگ کے بعد کتنی کوانٹم دھات ممکنہ طور پر حاصل کر سکتے ہیں، اور پراسیسنگ کے لیے کتنا وقت باقی ہے۔ بیٹری چارج کرنے کے لیے، اپ گریڈ بٹن دبائیں۔",

      STEP_15_TITLE: "بیٹری چارج کرنا",
      STEP_15_TEXT: "بیٹری چارج کرنے کے لیے مطلوبہ توانائی کی مقدار درج کریں اور 'لاگو کریں' پر کلک کریں۔",

      STEP_16_TITLE: "مکمل کوانٹم ویو",
      STEP_16_TEXT: "یہ ایک مکمل دھات پراسیسنگ ویو کی طرح دکھتا ہے۔ اب آپ اپنی حق دار کوانٹم دھات جمع کر سکتے ہیں۔"
    }
  },
};
