export const locale = {
  lang: 'ur',
  data: {
    FILTER_ORBIT: {
      LEVEL: 'Tier:',
      ALL: 'Delta',
      SHOW_LEVEL_LIST: 'Tier list',
    },
  },
};
