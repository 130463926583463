export const locale = {
  lang: 'ar',
  data: {
    UPGRADE_WAVE_MODAL: {
      UPGRADE: 'ترقية',
      FILL: 'املأ',
      FILL_INFO: 'استخدم رموز NTC الخاصة بك لملء الرقائق في معالجة خامك',
      LEARN_MORE: 'تعلم المزيد',
      YOU_HAVE: "متاح",
      SELECT: 'اختر',
      SET_MAX: 'ضبط الحد الأقصى',
      YOU_GET: "سوف تحصل",
      BUY: 'اشترِ',
      AVAILABLE: 'متاح',
      OF: 'من',
      ERROR: 'ليس لديك ما يكفي من NTC',
      UPGRADE_BUTTON: 'ترقية على X1',
    }
  },
};
