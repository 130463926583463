export const locale = {
  lang: 'hi',
  data: {
    EXTEND_CHOOSE_TYPE_MODAL: {
      TIER: "स्तर",
      ACTIVATION: "सक्रियण",
      YOU_WILL_GET: "आपको मिलेगा",
      ACTIVATE_CHIP: "सक्रियण चिप",
      NEW: "नया",
      CHIP_DESCRIPTION: "क्वांटम वेव स्टार्ट मशीन को सक्रिय करने के लिए चिप।",
      AMOUNT_CHIPS: "चिप्स की मात्रा",
      ACCESS_KEY: "पहुँच कुंजी",
      KEY_DESCRIPTION: "पहुंच समय बढ़ाएं।",
      KEY_DESCRIPTION_ACTIVATION: "एक्सेस कुंजी आपको उन स्तरों में प्रवेश प्रदान करती है जो मशीनों से सुसज्जित हैं, जो आपको क्वांटम वेव लॉन्च प्रक्रियाओं में भाग लेने की अनुमति देती हैं, साथ ही अयस्क को संसाधित करने और बिटफोर्स का खनन करने की क्षमता भी देती हैं।",
      AMOUNT_DAYS: "दिनों की संख्या",
      BUNDLE_SIZE: "बंडल का आकार",
      SELECT_PAYMENT: "भुगतान का तरीका चुनें",
      PRICE: "मूल्य",
      BALANCE: "शेष",
      SUPPORTED: "HoliDex द्वारा समर्थित",
      TOTAL: "कुल",
      ERROR_MSG: "बैलेंस शीट पर पर्याप्त धनराशि नहीं है",
      ACTIVATE: "सक्रिय करें",
      EXTEND_TIME: "समय बढ़ाएं",
      SPECIAL_PRICE: "विशेष मूल्य",
      BUYING: "HoliDex से खरीदना",
      HOLDING_BY:"द्वारा होल्डिंग",
      TYPE:"प्रकार",
      OPTIONS:"विकल्प",
      DAYS_LEFT:"दिन बचे हैं",
      HOLDING:"होल्डिंग",
      CAN_USED_ONCE_DURING_PROMOTION_PERIOD:"पदोन्नति अवधि के दौरान एक बार इस्तेमाल किया जा सकता",
      INSUFFICIENT_BALANCE:"अपर्याप्त संतुलन",
      MFS_VESTING:"HC वेस्टिंग",
      TIME:"समय",
      EXTEND:"विस्तार करें",
      SLIPPAGE_TOLERANCE:"स्लिपेज सहिष्णुता: ",
      LEVEL_ALREADY_BEEN_EXTENDED :"स्तर पहले ही हो चुका है</br> विस्तारित",
      TIMES_HMFS:"एचएमएफ द्वारा टाइम्स",
      YOU_DONT_HAVE_RIGHT_TYPE_HMFS:"आपके पास hHC का सही प्रकार नहीं है ।  आप इसे HC होल्डिंग या एक्सचेंज का उपयोग करके प्राप्त कर सकते हैं",
      STOCK_INFO1: 'मिफेस (HC) की आपका दर्शन भृगीएं डै (DAI) बोर्স से ली जाती है। यह अरथव्यवसायायं उपयोगी है कि मिफेस (HC) के लिए एक डै (DAI) की सूचना धुंधम खोली है।',
      STOCK_INFO2: "हम नहीं सicher कर सकते कि टرैザCTION का फ़राईस यह सcreen पर संगत रहेगा।",
      RENEWAL_FULL_PRICE:"पूर्ण मूल्य नवीकरण",
      RENEWAL_DISCOUNTED_PRICE:"छूट मूल्य पर नवीनीकरण",
      MFS_BY_DAI_exchange:"एक्सचेंज के माध्यम से HC के लिए DAI",
      EXTENDED_7_OR_MORE_TIMES:'विस्तारित 7 या अधिक बार',
      YOU_HAVE_LEFT: "शेष चिप्स",
      CHIPS_LEFT: "बची हुई चिप्स",
      COMPLETED_EXTENDED:'पूर्ण विस्तारित',
      FOR_THE_NEXT_EXTENDED_YOU_NEED:'अगले विस्तारित के लिए आपको जरूरत है',
      OR_MORE_TIMES:'या अधिक बार',
    },
  },
};
