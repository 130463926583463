export const locale = {
  lang: 'ar',
  data: {
    BREADCRUMB: {
      FORCE_SYSTEMS: 'أنظمة فورس',
      UNITEVERSE_DELTA: 'يونايتفيرس دلتا',
      FORCECOIN: 'فورس كوين',
      MATRIX: 'GameAf',
      RECENT_ACTIVITY: 'النشاط الأخير',
      LORE: 'Legends of Uniteverse',
      BACK: 'Back',
      OVERVIEW: 'تعدين',
      TIERS: 'مستويات',
      PROCESSING: 'موجات',
    },
  },
};
