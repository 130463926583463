export const locale = {
  lang: 'hi',
  data: {
    TREE: {
      FORCE_ID: 'Holiverse ID',
      HIDE: 'छुपाएं',
      SHOW_REFERRALS: 'रेफरल्स दिखाएं',
      UPLOAD: 'और 5 रेफरल अपलोड करें',
      WALLET: 'Wallet:',
      UV_TIER: 'UV टियर:',
      PROFIT: 'लाभ:',
    },
  },
};
