<div class="container-level d-flex align-items-center">
  <div class="d-flex w-100">
    <div class="level-text flex-sm-container-level">
      <div class="col-12-custom-sm-level">
        {{ 'FILTER_ORBIT.LEVEL' | translate }}
      </div>

      <div class="level-text space-level col-12-custom-sm-level"></div>
    </div>

    <div
      class="btn-group btn-group-toggle flex-sm-container"
      ngbRadioGroup
      name="radioBasic"
      (ngModelChange)="setLevel($event)"
      [(ngModel)]="radioModel">
      <div class="col-12-custom-sm d-flex">
        <label ngbButtonLabel class="btn-levels btn-levels-fist"
          ><input ngbButton type="radio" [value]="0" />{{
            'FILTER_ORBIT.ALL' | translate
          }}
        </label>
        <label ngbButtonLabel class="btn-levels"
          ><input ngbButton type="radio" [value]="1" />1
        </label>
        <label ngbButtonLabel class="btn-levels"
          ><input ngbButton type="radio" [value]="2" />2
        </label>
        <label ngbButtonLabel class="btn-levels"
          ><input ngbButton type="radio" [value]="3" />3
        </label>
        <label ngbButtonLabel class="btn-levels btn-levels-last"
          ><input ngbButton type="radio" [value]="4" />4
        </label>
      </div>
      <div class="col-12-custom-sm d-flex">
        <label ngbButtonLabel class="btn-levels btn-levels-fist"
          ><input ngbButton type="radio" [value]="5" />5
        </label>
        <label ngbButtonLabel class="btn-levels"
          ><input ngbButton type="radio" [value]="6" />6
        </label>
        <label ngbButtonLabel class="btn-levels"
          ><input ngbButton type="radio" [value]="7" />7
        </label>
        <label ngbButtonLabel class="btn-levels"
          ><input ngbButton type="radio" [value]="8" />8
        </label>
        <label ngbButtonLabel class="btn-levels btn-levels-last"
          ><input ngbButton type="radio" [value]="9" />9
        </label>
      </div>
    </div>
  </div>
  <div class="level-buy-btn" routerLink="/pages/tier-list">
    {{ 'FILTER_ORBIT.SHOW_LEVEL_LIST' | translate }}
    <span class="material-icons"> subject </span>
  </div>
</div>
