export const locale = {
  lang: 'en',
  data: {
    CHIP: {
      CHIP_LEVEL: 'Chip level',
      ALL_CHIPS: 'All chips',
      EXPAND_CHIPS: 'Expand chips',
      FILLABLE_CHIPS: 'Fillable chips',
      UPGRADE: 'Upgrade',
    },
  },
};
