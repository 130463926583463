export const locale = {
  lang: 'hi',
  data: {
    CLAIM_MODAL: {
      CLAIM: "दावा करें",
      TITLE: "नई लाभ पर बधाई",
      SUBTITLE: "खनिज खनन पूरा हो गया है, आप अब अपनी पुरस्कार पर मोहर लगा सकते हैं",
      REWARD: "आपका पुरस्कार",
    }
  },
};
