export const locale = {
  lang: 'hi',
  data: {
    CONFIRM_BUYBACK_MODAL: {
      TITLE: 'ध्यान दें',
      INFO: 'आप पुष्टि करते हैं कि आप निर्धारित बिक्री मूल्य से अवगत हैं: 1 HC: {{price}} DAI, और आप इससे सहमत हैं। आप यह भी पुष्टि करते हैं कि आपको इस विनिमय दर और मंच के प्रति कोई आपत्ति नहीं है।',
      AGREEMENT: 'मैं सब कुछ पुष्टि करता हूँ',
      YES: 'हाँ',
      NO: 'नहीं',
    },
  },
};
