export const locale = {
  lang: 'zh',
  data: {
    VESTING_TARIFFS: {
      TITLE: '选择套餐',
      BALANCES: '您的余额',
      NO_VESTING_BALANCE_INFO: '您的账户中没有累积余额。没有余额时无法进行累积。',
      TARIFF_1_NAME: '两个月的资费',
      TARIFF_2_NAME: '1年套餐',
      TARIFF_3_NAME: '2年套餐',
      TARIFF_4_NAME: '3年套餐',
      TARIFF_5_NAME: '4年套餐',
      TARIFF_6_NAME: '5年套餐',
      DURATION: '期限：',
      TARIFF_1_DURATION: '2个月',
      TARIFF_2_DURATION: '1年',
      TARIFF_3_DURATION: '2年',
      TARIFF_4_DURATION: '3年',
      TARIFF_5_DURATION: '4年',
      TARIFF_6_DURATION: '5年',
      TARIFF_1_DESCRIPTION: '如果您选择此资费，您的HC递延账户余额中的50%将作为资金费用。总HC的50%将可用。',
      TARIFF_2_DESCRIPTION: '选择此套餐将支付剩余*您的HC累积总余额的30%作为资金费用。因此，您将保留70%的HC。',
      TARIFF_3_DESCRIPTION: '选择此套餐将支付剩余*您的HC累积总余额的15%作为资金费用。因此，您将保留85%的HC。',
      TARIFF_4_DESCRIPTION: '标准计划。无需费用，也不会获得额外的HC。',
      TARIFF_5_DESCRIPTION: '将保留当前的HC累积余额，并额外分配30%的资金池。',
      TARIFF_6_DESCRIPTION: '将保留当前的HC累积余额，并额外分配70%的资金池。',
      TARIFFS_COMMON_INFO: 'HC累积由两个阶段组成。第一阶段是每个用户余额的10%释放，第一个月还会额外释放HC以延长等级。第二阶段是释放剩余余额的90%或更少，如果参与了释放HC以延长等级。套餐选择仅适用于第二阶段。默认情况下，3年套餐已激活，如果您不打算更改它，则无需在此页面上进行任何选择。',
      TARIFFS_COMMON_INFO_WARNING: '套餐选择只能进行一次。无法第二次更改套餐。例如：当前默认选择的是3年套餐。您选择了4年套餐，之后您将无法更改。',
      TARIFFS_INFO: '所有累积代币将在期限结束前发放。',
      TARIFFS_DISABLED_BIG: '4年套餐和5年套餐将在计算基金规模后开放。',
      TARIFFS_DISABLED_SMALL: "2个月、1年和2年的资费计划将在基金量计算完成后提供。",
      SELECT_TARIFF: '选择套餐',
      CURRENT_TARIFF: '您的套餐',
      DEFAULT: '默认',
      USER_WILL_RECEIVE: '您将在期限结束前获得：',
      FUND_WILL_RECEIVE: '您的资金将支付给基金：',
      FUND_RECEIVED: '基金中：',
      FUNDING_AMOUNT: '资金规模',
      BURN: '将有15%被销毁',
      BUYBACK_INFO: "如果您不想等待通过Messenger接收硬币，您可以了解更多关于额外条款",
      BUYBACK_LINK: "点击这里",
    },
  },
};
