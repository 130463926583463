export const locale = {
  lang: 'ar',
  data: {
    EXTEND_CHOOSE_TYPE_MODAL: {
      BUTTON_BACK: 'العودة',
      EXTAND_TIER: 'تمديد الطبقة',
      TWO_TYPES_EXTENSIONS_AVAILABLE: 'هناك نوعان من التمديدات المتاحة',
      EXTEND_TIME_IN: 'تمديد الوقت بـ',
      PRICE_REDUCED_BY: 'السعر مخفض بمقدار',
      YOUR_BALANCE: 'رصيدك ',
      EXTEND: 'تمديد ',
      INSUFFICIENT_FUNDS: 'الأموال غير كافية',
    },
  },
};
