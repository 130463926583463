export const locale = {
  lang: 'fr',
  data: {
    EXTEND_CHOOSE_TYPE_MODAL: {
      TIER: "Niveau",
      ACTIVATION: "Activation",
      YOU_WILL_GET: "Vous obtiendrez",
      ACTIVATE_CHIP: "Puce d'activation",
      NEW: "nouveau",
      CHIP_DESCRIPTION: "Puce pour activer la machine de démarrage de l'onde quantique.",
      AMOUNT_CHIPS: "quantité de jetons",
      ACCESS_KEY: "Clé d'accès",
      KEY_DESCRIPTION: "Prolonger le temps d'accès.",
      KEY_DESCRIPTION_ACTIVATION: "La clé d'accès permet d'entrer dans des niveaux équipés de machines qui vous permettent de participer aux processus de lancement d'ondes quantiques, ainsi que de traiter le minerai et d'extraire le Bitforce.",
      AMOUNT_DAYS: "nombre de jours",
      BUNDLE_SIZE: "Taille du lot",
      SELECT_PAYMENT: "Sélectionnez un mode de paiement",
      PRICE: "Prix",
      BALANCE: "Solde",
      SUPPORTED: "Pris en charge par HoliDex",
      TOTAL: "Total",
      ERROR_MSG: "Il n'y a pas assez de fonds sur le bilan",
      ACTIVATE: "Activer",
      EXTEND_TIME: "Prolonger le temps",
      SPECIAL_PRICE: "PRIX SPÉCIAL",
      BUYING: "Acheter chez HoliDex",
      HOLDING_BY:"TENIR par",
      TYPE:"Taper",
      OPTIONS:"Options",
      DAYS_LEFT:"Jours restants",
      HOLDING:"Holding",
      CAN_USED_ONCE_DURING_PROMOTION_PERIOD:"Peut être utilisé une fois pendant la période de promotion",
      INSUFFICIENT_BALANCE:"Solde insuffisant",
      MFS_VESTING:"ACCORDS HC",
      TIME:"temps",
      EXTEND:"Étendre",
      SLIPPAGE_TOLERANCE:"TOLÉRANCE AU GLISSEMENT: ",
      LEVEL_ALREADY_BEEN_EXTENDED :"Le niveau a déjà été</br> étendu",
      TIMES_HMFS:"fois par hHC",
      YOU_DONT_HAVE_RIGHT_TYPE_HMFS:"Vous n'avez pas le bon type de hHC. Vous pouvez l'obtenir en utilisant la détention HC ou un échange",
      STOCK_INFO1: "Le prix des HC est prélevé sur la boutique HoliDex. Cela signifie que le prix d'un HC dans le DAI fluctue constantement.",
      STOCK_INFO2: "Nous ne pouvons pas assurer que le frais du transfert sera égal lorsque vous créez une transaction à celui présenté sur cet écran.",
      RENEWAL_FULL_PRICE:"Renouvellement au plein tarif",
      RENEWAL_DISCOUNTED_PRICE:"Renouvellement à prix réduit",
      MFS_BY_DAI_exchange:"HC pour DAI via échange",
      EXTENDED_7_OR_MORE_TIMES:'Renouvelé 7 fois ou plus',
      YOU_HAVE_LEFT: "jetons restants",
      CHIPS_LEFT: "Chips restantes",
      COMPLETED_EXTENDED:'terminé étendu',
      FOR_THE_NEXT_EXTENDED_YOU_NEED:'pour la prochaine extension, vous avez besoin',
      OR_MORE_TIMES:'ou plusieurs fois',
    },
  },
};
