export const locale = {
  lang: 'id',
  data: {
    LEVEL_ITEM: {
      TIER: 'Tingkat',
      ACTIVE_UNTIL: 'Aktif sampai',
      ACTIVATE: 'Aktifkan',
      EXTENSION_TIME: 'Waktu perpanjangan',
      EXTEND_TIME: 'Perpanjang',
      DELTA: 'Delta',
      BADGE_READY: 'Siap',
      BADGE_EXTENSION: 'Perpanjangan',
      BADGE_ACTIVE: 'Aktif',
      BADGE_NO_ACTIVE: 'Tidak aktif',
      OR: 'atau',
      CANT_EXTEND: "SUDAH DI AKTIFKAN",
      EXTEND_TEXT: 'sampai perpanjangan:',
    },
  },
};
