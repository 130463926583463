export const locale = {
  lang: 'fr',
  data: {
    MENU: {
      HOME: 'Accueil',
      FORCE_SYSTEM: 'Système de force',
      UNITEVERSE: 'Universe',
      FORCECOIN: 'Holichain',
      MY_TEAM: 'Mon équipe',
      ACADEMY: 'Académie',
      SETTINGS: 'Paramètres',
      HELP: 'Aide',
      SOCIAL_META_FORCE: 'Force méta sociale',
      MESSENGER: 'Messenger',
      BADGE_MESSENGER: 'Beta',
    },
  },
};
