export const locale = {
  lang: 'ar',
  data: {
    SIDE_NAV_WALLET_BUYBACK: {
      BACK: "العودة",
      TITLE: "البيع المبكر لـ HC",
      IMPORTANTLY: "الأهم",
      INFO1: "هذه الأداة تتيح لك بيع HC الخاصة بك خارج الدور، بما في ذلك الأموال في رصيد الاستحقاق. سعر HC عند استخدام هذه الأداة هو 1 HC: ${price} DAI.",
      INFO2: "* عند بيع العملات من رصيد الاستحقاق، سيحدث إعادة توزيع. المبلغ المتبقي من العملات في رصيد الاستحقاق بعد البيع سيتم إطلاقه بالتساوي على مدى الفترة المتبقية من الاستحقاق.",
      INFO3: "يتم بيع HC في هذا الترتيب: <ul><li>أولاً HC الحرة من الاستحقاق؛</li><li>ثم يتم بيع HC من رصيد الاستحقاق.</li></ul>تقوم هذه الأداة بشراء HC بحدود. إذا انتهى الحد الحالي ، فيجب عليك الانتظار حتى يتم فتح الحد التالي.",
      YOU_GIVE: "أنت تعطي",
      AVAILABLE: "متاح",
      MAX: "الأقصى",
      YOU_RECEIVE: "أنت تستلم",
      SELL: 'بيع',
      OPERATIONS: "العمليات",
      COMPLETED: "مكتمل",
      AMOUNT: "المبلغ الإجمالي",
      NOT_AVAILABLE: "لا يوجد داي متاح",
      OFFER_YOUR_PRICE: "قدم سعرك الخاص",
      BUYBACK_OFFER_REQUEST_YOUR_PRICE: 'تم قبول طلب العرض الخاص بك. يمكنك شراء HC بسعر العرض المقدم.',
    }
  },
};
