export const locale = {
  lang: 'ru',
  data: {
    PURCHASE: {
      ACTIVATE: 'Для активации тьира',
      PURCHASE: 'необходимо активировать тьир',
      ACCESS: 'срок действия 30 дней',
    },
  },
};
