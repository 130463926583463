export const locale = {
  lang: 'ar',
  data: {
    TREE: {
      FORCE_ID: 'معرّف القوة',
      HIDE: 'إخفاء',
      SHOW_REFERRALS: 'عرض الإحالات',
      UPLOAD: 'تحميل 5 إحالات إضافية',
      WALLET: 'Wallet:',
      UV_TIER: 'طبقة اليونيفيرس:',
      PROFIT: 'الربح:',
    },
  },
};
