export const locale = {
  lang: 'ur',
  data: {
    SIDE_NAV_WALLET_BUYBACK: {
      BACK: "واپس",
      TITLE: "HC کی جلد فروخت.",
      IMPORTANTLY: "اہم بات",
      INFO1: "یہ ٹول آپ کو مارکیٹ کے باہر آپ کے HC کو فروخت کرنے کی اجازت دیتا ہے، جس میں ویسٹنگ بیلنس میں موجود فنڈز بھی شامل ہیں۔ اس ٹول کو استعمال کرتے وقت HC کی قیمت 1 HC: ${price} DAI ہے۔",
      INFO2: "* ویسٹنگ بیلنس سے سکوں کو فروخت کرنے پر، دوبارہ تقسیم ہوگی۔ فروخت کے بعد ویسٹنگ بیلنس میں باقی سکوں کی رقم باقی ویسٹنگ مدت کے دوران برابری سے جاری کی جائے گی۔",
      INFO3: "HC اس ترتیب میں فروخت ہوتے ہیں: <ul><li>پہلے ویسٹنگ سے آزاد HC؛</li><li>پھر ویسٹنگ بیلنس سے HC فروخت ہوتے ہیں۔</li></ul>یہ ٹول HC کو خریدتا ہے حدود کے ساتھ۔ اگر موجودہ حد ختم ہوگئی ہے تو آپ کو اگلی حد کا انتظار کرنا ہوگا۔",
      YOU_GIVE: "آپ دیتے ہیں",
      AVAILABLE: "دستیاب",
      MAX: "زیادہ سے زیادہ",
      YOU_RECEIVE: "آپ وصول کرتے ہیں",
      SELL: 'بیچیں',
      OPERATIONS: "آپریشنز",
      COMPLETED: "مکمل",
      AMOUNT: "کل رقم",
      NOT_AVAILABLE: "DAI دستیاب نہیں",
      OFFER_YOUR_PRICE: "اپنی قیمت پیش کریں",
      BUYBACK_OFFER_REQUEST_YOUR_PRICE: 'آپ کی پیشکش کی درخواست کی گئی ہے۔ آپ پیشکش کی قیمت پر HC خرید سکتے ہیں۔',
    },
  },
};
