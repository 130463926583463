export const locale = {
  lang: 'ru',
  data: {
    SIDE_NAV_WALLET_ACTION: {
      BACK: 'Назад',
      SELL_MFC: 'Продать HC',
      IMPORTANTLY: 'Важно',
      INFO: 'Здесь Вы можете выставить свои HC на продажу по цене 2.77 DAI за 1 HC. Ваши HC будут проданы пользователям, у которых нет HC для активации тьиров в Uniteverse. Заявки обрабаываются в порядке очереди. При частичной продажи, заявка отправляется в конец очереди.',
      YOU_GIVE: 'ВЫ ДАЕТЕ',
      AVAILABLE: 'ДОСТУПНО',
      MAX: 'Макс',
      YOU_RECEIVE: 'ВЫ ПОЛУЧАЕТЕ',
      SELL: 'Продать',
      OPEN: 'Открыть',
      QUEUE: 'ваша очередь через',
      OPERATIONS: 'Операции',
      CANCEL_ORDER: 'Отменить заказ',
      COMPLETED: 'Завершено',
      AMOUNT: 'Общая сумма',
      BUYBACK_INFO: 'Желаете продать мгновенно без очереди!? Больше информации можно',
      BUYBACK_LINK: 'получить тут',
    },
  },
};
