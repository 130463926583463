export const locale = {
  lang: 'id',
  data: {
    TREE: {
      FORCE_ID: 'Holiverse ID',
      HIDE: 'Hide',
      SHOW_REFERRALS: 'Show referals',
      UPLOAD: 'Upload 5 more referals',
      WALLET: 'Wallet:',
      UV_TIER: 'UV tier:',
      PROFIT: 'Profit:',
    },
  },
};
