export const locale = {
  lang: 'fr',
  data: {
    VESTING_TARIFFS: {
      TITLE: 'Choisissez un tarif',
      BALANCES: 'Votre solde',
      NO_VESTING_BALANCE_INFO: 'Vous n\'avez pas de solde de report sur votre compte. L\'accumulation n\'est pas disponible sans solde.',
      TARIFF_1_NAME: 'Tarif de vesting de 2 mois',
      TARIFF_2_NAME: 'Tarif 1 an de report',
      TARIFF_3_NAME: 'Tarif 2 ans de report',
      TARIFF_4_NAME: 'Tarif 3 ans de report',
      TARIFF_5_NAME: 'Tarif 4 ans de report',
      TARIFF_6_NAME: 'Tarif 5 ans de report',
      DURATION: 'Durée : ',
      TARIFF_1_DURATION: '2 mois',
      TARIFF_2_DURATION: '1 an',
      TARIFF_3_DURATION: '2 ans',
      TARIFF_4_DURATION: '3 ans',
      TARIFF_5_DURATION: '4 ans',
      TARIFF_6_DURATION: '5 ans',
      TARIFF_1_DESCRIPTION: 'En choisissant ce tarif, un prélèvement de 50% du solde restant* de votre balance totale de vesting HC sera effectué. Au total, 50% de HC resteront disponibles.',
      TARIFF_2_DESCRIPTION: 'En choisissant ce tarif, vous paierez 30% du solde total restant* de votre solde de report HC. Il restera donc 70% de HC disponibles.',
      TARIFF_3_DESCRIPTION: 'En choisissant ce tarif, vous paierez 15% du solde total restant* de votre solde de report HC. Il restera donc 85% de HC disponibles.',
      TARIFF_4_DESCRIPTION: 'Plan standard. Aucun coût ni gain supplémentaire en HC.',
      TARIFF_5_DESCRIPTION: 'Vous permet de conserver votre solde de report HC actuel et de partager 30% du fonds de financement.',
      TARIFF_6_DESCRIPTION: 'Vous permet de conserver votre solde de report HC actuel et de partager 70% du fonds de financement.',
      TARIFFS_COMMON_INFO: 'Le report HC se compose de deux étapes. La première étape consiste à libérer 10% du solde de chaque utilisateur, avec une libération supplémentaire de HC pour prolonger les niveaux au cours du premier mois. La deuxième étape du report concerne les soldes restants de 90% ou moins, si une participation à la libération de HC pour prolonger les niveaux a eu lieu. Le choix du tarif de report s\'applique uniquement à la deuxième étape. Le tarif de 3 ans est activé par défaut, donc si vous ne prévoyez pas de le changer, vous n\'avez rien à sélectionner sur cette page.',
      TARIFFS_COMMON_INFO_WARNING: 'Le choix du tarif est définitif. Il ne sera pas possible de le modifier une deuxième fois. Exemple : le tarif de 3 ans est actuellement sélectionné par défaut. Vous choisissez le tarif de 4 ans, après quoi vous ne pourrez plus le modifier.',
      TARIFFS_INFO: 'Tous les jetons de report seront émis avant la fin de la période.',
      TARIFFS_DISABLED_BIG: 'Les tarifs 4 et 5 seront disponibles ultérieurement, après le calcul du volume du fonds.',
      TARIFFS_DISABLED_SMALL: "Les tarifs pour 2 mois, 1 an et 2 ans seront disponibles plus tard, après le calcul des volumes du fonds.",
      SELECT_TARIFF: 'Choisissez un tarif',
      CURRENT_TARIFF: 'Votre tarif',
      DEFAULT: 'par défaut',
      USER_WILL_RECEIVE: 'Vous recevrez jusqu\'à la fin de la période :',
      FUND_WILL_RECEIVE: 'Votre paiement dans le fonds de financement :',
      FUND_RECEIVED: 'Dans le fonds :',
      FUNDING_AMOUNT: 'Montant du financement',
      BURN: '15% seront brûlés',
      BUYBACK_INFO: "Si vous ne souhaitez pas attendre pour obtenir les monnaies du messager, vous pouvez vous renseigner sur les conditions supplémentaires",
      BUYBACK_LINK: "en cliquant ici",
    },
  },
};
