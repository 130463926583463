export const locale = {
  lang: 'ar',
  data: {
    MARKETING_TABEL: {
      FORCE_ID: 'معرّف القوة',
      ORBIT: 'المدار',
      WALLET: 'المحفظة:',
      UV_TIER: 'طبقة UV:',
      PROFIT: 'الربح:',
      LINE: 'الخط',
    },
  },
};
