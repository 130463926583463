export const locale = {
  lang: 'id',
  data: {
    EXPAND_CHIP_MODAL: {
      EXPAND: "Expand",
      FILL: "Fill",
      CHIP: 'Chip',
    },
  },
};
