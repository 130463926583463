export const locale = {
  lang: 'en',
  data: {
    SIDE_NAV_HOLD_MFS_WALLET: {
      BACK: 'Back',
      HOLD_MFS:'Hold HC',
      HOLD_HMFS:'Hold hHC',
      HOLD: 'HOLD',
      DAY: 'day',
      GET: 'GET',
      INNER_BALANCE: 'Inner Balance',
      HMFS_TO_MFS: 'hHC to HC',
      TAKE_MFS:'TAKE HC',
      TAKE_HMFS:'TAKE hHC',
    },
  }
};
