export const locale = {
  lang: 'ar',
  data: {
    GAME_HELP: {
      TITLE: "إرشادات",
      STEP_0_TILE: "Uniteverse",
      STEP_0_TEXT: "اكتشف كيف يعمل كل شيء",
      STEP_0_BUTTON: "استمر",

      STEP_1_TITLE: "الصفحة الرئيسية: مدخل منجم Uniteverse",
      STEP_1_TEXT: "تعمل هذه الصفحة كمدخل إلى منجم Uniteverse، حيث يمكنك عرض والوصول إلى المعلومات الأساسية. هنا، تُعرض مقاييسك الأساسية والحالات المهمة التي تعكس تقدمك وحالتك الحالية.",

      STEP_2_TITLE: "الأرباح الإجمالية والرصيد",
      STEP_2_TEXT: "في هذه اللوحة، يمكنك مراقبة رصيدك الحالي من عملات الطاقة والموارد.",

      STEP_3_TITLE: "تعدين الطاقة",
      STEP_3_TEXT: "باستخدام هذا الجهاز، يمكنك تعدين طاقة NTC (NetCharge) كل 24 ساعة. اجمع الطاقة يوميًا لشحن بطارياتك بالكامل في جهاز معالجة الخام. يزيد شحن البطارية من كمية الخام غير المكرر التي يمكن معالجتها، مما يعزز إنتاجيتك الإجمالية.",

      STEP_4_TITLE: "مفتاح الوصول والمستويات",
      STEP_4_TEXT: "بتفعيل مفتاح الوصول إلى المستوى، يمكنك الوصول إلى المستوى للمشاركة في إطلاق الموجات الكمومية، يليه معالجة الخام وتعدين Bitforce. عند التفعيل وتمديد الوصول، تحصل أيضًا على رقاقة يتم تثبيتها تلقائيًا في جهاز إطلاق الموجة الكمومية. بعد إطلاق الموجة الكمومية، يمكنك معالجة الخام المستخرج في تلك الموجة الكمومية." +
          "\n\n" +
          "في أعلى الشاشة، يمكنك مراقبة حالة إطلاق الموجة الكمومية الحالية. أدناه يوجد قسم بخطة مقطعية للمنجم، تشير إلى عمق المستويات وتعرض وصولات المستويات النشطة لديك.",

      STEP_5_TITLE: "دخول المستويات",
      STEP_5_TEXT: "للدخول، انقر على أي مستوى نشط. هنا، يمكنك إدارة عمليات معالجة الموارد واستخراجها.",

      STEP_6_TITLE: "صفحة المنجم",
      STEP_6_TEXT: "في هذه الصفحة، يمكنك رؤية المستويات التي لديك وصول إليها. تحتوي هذه المستويات على آلات مختلفة.",

      STEP_7_TITLE: "لوحة التحكم",
      STEP_7_TEXT: "في هذه اللوحة، يمكنك تتبع عدد الرقائق الخاصة بك المشاركة في إطلاق الموجة الكمومية الحالية. بالإضافة إلى ذلك، تعرض أيضًا المستويات التي لديك وصول إليها.",

      STEP_8_TITLE: "مساحة المستوى",
      STEP_8_TEXT: "تحتوي كل مساحة مستوى على آلاتها الفريدة. هنا، يمكنك رؤية وقت الوصول إلى المستوى الحالي، وحالة جاهزية آلات إطلاق الموجة الكمومية، بالإضافة إلى نظرة عامة على شبكة GameAf.",

      STEP_9_TITLE: "تمديد المستوى",
      STEP_9_TEXT: "قم بتمديد وصولك إلى مستوى، والذي يُمنح في البداية لمدة 30 يومًا، لمواصلة استخدامه. إذا لم تكن المستويات السابقة نشطة، فإن التجديد متاح فقط بعقوبة." +
          "\n\n" +
          "عند تمديد مستوى، يتم تخصيص رقاقة أيضًا لجهاز لإطلاق موجة كمومية من نفس المستوى." +
          "\n\n" +
          "عرض الأيام المتبقية حتى انتهاء تفعيل المستوى الحالي.",

      STEP_10_TITLE: "تفعيل مفتاح الوصول إلى المستوى",
      STEP_10_TEXT: "في هذا المثال، سنقوم بتفعيل الوصول إلى المستوى 3.",

      STEP_11_TITLE: "تفعيل مفتاح الوصول إلى المستوى",
      STEP_11_TEXT: "تعرض هذه النافذة عدد الرقائق ووقت الوصول الذي ستحصل عليه. يتم الدفع بعملة HC. إذا لم تكن لديك في حسابك، يمكنك اختيار الخيار الأول، الذي سيشتري HC تلقائيًا من السوق بالسعر الحالي." +
          "\n\n" +
          "عند اختيار تفعيل أو تمديد متعدد، سيتم تثبيت الرقاقة الأولى المستلمة لهذا المستوى في الجهاز لإطلاق الموجة الكمومية الحالية. سيتم استخدام الرقاقة الثانية لإطلاق الموجة الكمومية بعد 30 يومًا من إطلاق الموجة بالرقاقة الأولى. سيتم تفعيل الرقاقة الثالثة بعد 30 يومًا من إطلاق الموجة بالرقاقة الثانية، وهكذا.",

      STEP_12_TITLE: "المساحة الكمومية",
      STEP_12_TEXT: "في المساحة التالية، هناك جهازان مشتركان متاحان لجميع المستويات. يقوم الجهاز الأول بمعالجة الخام إلى خام كمومي، بينما يقوم الجهاز الثاني بتعدين Bitforce.",

      STEP_13_TITLE: "جهاز معالجة الخام",
      STEP_13_TEXT: "لنلقِ نظرة أقرب على كيفية عمل جهاز معالجة الخام." +
          "\n\n" +
          "بدءًا من البداية: سيتم بدء موجة كمومية عند تفعيل 88 جهازًا. ستستخرج هذه الموجة الخام الخام. ستحدد الرقائق المشاركة في إطلاق الموجة الكمية المحتملة القصوى من الخام التي يمكنك معالجتها في هذا الجهاز على مدى 30 يومًا. بعد هذه الأيام الثلاثين، سيكون لديك الفرصة لجمع الخام الكمومي." +
          "\n\n" +
          "لمعالجة الخام، تحتاج إلى الحفاظ على بطارياتك مشحونة. افتراضيًا، لديك بطارية واحدة، ولكن قريبًا ستتاح لك خيار شراء بطارية إضافية. لذلك، إذا شاركت بالكامل في معالجة الخام، ستحصل على أقصى كمية ممكنة من الخام الكمومي. ومع ذلك، إذا لم تشارك في معالجة الخام، فلن تحصل على أي شيء.",

      STEP_14_TITLE: "شحن البطارية",
      STEP_14_TEXT: "هنا، يمكنك رؤية كمية الخام الكمومي التي يمكنك الحصول عليها بعد معالجة الخام، وكم من الوقت المتبقي للمعالجة. لشحن البطارية، اضغط على زر الترقية.",

      STEP_15_TITLE: "شحن البطارية",
      STEP_15_TEXT: "أدخل كمية الطاقة المطلوبة لشحن البطارية واضغط على 'تطبيق'.",

      STEP_16_TITLE: "الموجة الكمومية المكتملة",
      STEP_16_TEXT: "هذا هو شكل موجة معالجة الخام المكتملة. يمكنك الآن جمع الخام الكمومي المستحق لك."
    }
  },
};