export const locale = {
  lang: 'zh',
  data: {
    UPGRADE_WAVE_MODAL: {
      UPGRADE: '升级',
      FILL: '填充',
      FILL_INFO: '利用您的NTC代币填充矿石处理中的芯片',
      LEARN_MORE: '了解更多',
      YOU_HAVE: "可用",
      SELECT: '选择',
      SET_MAX: '设置最大值',
      YOU_GET: "您将获得",
      BUY: '购买',
      AVAILABLE: '可用',
      OF: '的',
      ERROR: '您没有足够的NTC',
      UPGRADE_BUTTON: '在X1上升级',
    }
  },
};
