export const locale = {
  lang: 'en',
  data: {
    FILTERS_PANEL: {
      FILTER: "Filter",
      WAVE_FILTER_ALL: 'All',
      WAVE_FILTER_COMPLETED: 'Completed',
      WAVE_FILTER_STARTED: 'Started',
    },
  },
};
