export const locale = {
  lang: 'zh',
  data: {
    GAME_HELP: {
      TITLE: "指南",
      STEP_0_TILE: "Uniteverse",
      STEP_0_TEXT: "探索其工作原理",
      STEP_0_BUTTON: "继续",

      STEP_1_TITLE: "主页：Uniteverse矿井入口",
      STEP_1_TEXT: "此页面是Uniteverse矿井的入口，您可以查看和访问关键信息。在这里，显示了您的基本指标和反映您当前进度和状态的重要状态。",

      STEP_2_TITLE: "总收入和余额",
      STEP_2_TEXT: "在此面板上，您可以监控当前的能量币和资源余额。",

      STEP_3_TITLE: "能量采矿",
      STEP_3_TEXT: "使用此设备，您可以每24小时开采NTC（NetCharge）能量。每天收集能量以在矿石处理设备中充满电池。充电电池可以增加可处理的未精炼矿石量，从而提高整体产量。",

      STEP_4_TITLE: "访问密钥和层级",
      STEP_4_TEXT: "通过激活层级访问密钥，您可以访问该层级以参与启动量子波，然后是矿石处理和Bitforce开采。在激活和扩展访问权限时，您还会收到一个自动安装在量子波启动设备中的芯片。在量子波启动后，您可以处理在该量子波中开采的矿石。" +
          "\n\n" +
          "在屏幕顶部，您可以监控当前量子波启动的状态。下面是矿井的横截面计划，指示层级的深度并显示您的活动层级访问权限。",

      STEP_5_TITLE: "进入层级",
      STEP_5_TEXT: "要进入，请点击任何活动层级。在这里，您可以管理资源处理和开采的过程。",

      STEP_6_TITLE: "矿井页面",
      STEP_6_TEXT: "在此页面上，您可以看到您有权访问的层级。这些层级包含各种机器。",

      STEP_7_TITLE: "控制面板",
      STEP_7_TEXT: "在此面板上，您可以跟踪有多少芯片参与了当前量子波的启动。此外，它还显示您有权访问的层级。",

      STEP_8_TITLE: "层级空间",
      STEP_8_TEXT: "每个层级空间都有其独特的机器。在这里，您可以看到当前层级的访问时间、量子波启动机器的准备状态以及GameAf网络的概览。",

      STEP_9_TITLE: "层级扩展",
      STEP_9_TEXT: "将您的层级访问权限延长，最初授予30天，以继续使用。如果以前的层级未激活，则仅在支付罚款的情况下可续订。" +
          "\n\n" +
          "当您扩展层级时，一个芯片也会被分配到设备中以启动同一层级的量子波。" +
          "\n\n" +
          "查看当前层级激活到期的剩余天数。",

      STEP_10_TITLE: "层级访问密钥激活",
      STEP_10_TEXT: "在此示例中，我们将激活对第3层的访问。",

      STEP_11_TITLE: "层级访问密钥激活",
      STEP_11_TEXT: "此窗口显示您将收到的芯片数量和访问时间。付款以HC进行。如果您的账户中没有，您可以选择第一个选项，该选项将以当前汇率自动从市场购买HC。" +
          "\n\n" +
          "当选择多个激活或扩展时，为该层级接收到的第一个芯片将安装在机器中以启动当前量子波。第二个芯片将在第一个芯片启动波浪30天后用于量子波启动。第三个芯片将在第二个芯片启动波浪30天后激活，依此类推。",

      STEP_12_TITLE: "量子空间",
      STEP_12_TEXT: "在下一个空间中，有两个可供所有层级使用的共享机器。第一台机器将矿石处理成量子矿石，而第二台机器开采Bitforce。",

      STEP_13_TITLE: "矿石处理机",
      STEP_13_TEXT: "让我们仔细看看矿石处理机的工作原理。" +
          "\n\n" +
          "从头开始：当88台设备被激活时，将启动一个量子波。此波将提取原矿石。参与启动波的芯片将决定您在此设备中30天内可处理的矿石的潜在最大量。经过这30天，您将有机会收集量子矿石。" +
          "\n\n" +
          "要处理矿石，您需要保持电池充电。默认情况下，您有一个电池，但很快您将有机会购买额外的电池。因此，如果您完全参与矿石处理，您将获得最大可能量的量子矿石。然而，如果您不参与矿石处理，您将不会获得任何东西。",

      STEP_14_TITLE: "电池充电",
      STEP_14_TEXT: "在这里，您可以看到在处理矿石后可以获得多少量子矿石，以及处理剩余的时间。要充电电池，请按升级按钮。",

      STEP_15_TITLE: "电池充电",
      STEP_15_TEXT: "输入所需的能量量以为电池充电，然后点击“应用”。",

      STEP_16_TITLE: "完成的量子波",
      STEP_16_TEXT: "这就是完成的矿石处理波的样子。您现在可以收集应得的量子矿石。"
    }
  },
};
