export const locale = {
  lang: 'ar',
  data: {
    FILTER_ORBIT: {
      LEVEL: 'الطبقة:',
      ALL: 'الكل',
      SHOW_LEVEL_LIST: 'قائمة الطبقات',
    },
  },
};
