export const locale = {
  lang: 'id',
  data: {
    EXTEND_CHOOSE_TYPE_MODAL: {
      TIER: "Tingkatan",
      ACTIVATION: "Aktivasi",
      YOU_WILL_GET: "Anda akan mendapatkan",
      ACTIVATE_CHIP: "Chip aktivasi",
      NEW: "baru",
      CHIP_DESCRIPTION: "Chip untuk Mengaktifkan Mesin Pemula Gelombang Kuantum.",
      AMOUNT_CHIPS: "jumlah keripik",
      ACCESS_KEY: "Kunci akses",
      KEY_DESCRIPTION: "Perpanjang waktu akses.",
      KEY_DESCRIPTION_ACTIVATION: "Kunci akses memberikan akses ke tingkatan yang dilengkapi dengan mesin yang memungkinkan Anda berpartisipasi dalam proses peluncuran gelombang kuantum, serta memproses bijih dan menambang Bitforce.",
      AMOUNT_DAYS: "jumlah hari",
      BUNDLE_SIZE: "Ukuran paket",
      SELECT_PAYMENT: "Pilih metode pembayaran",
      PRICE: "Harga",
      BALANCE: "Saldo",
      SUPPORTED: "Didukung oleh HoliDex",
      TOTAL: "Total",
      ERROR_MSG: "Tidak cukup dana di neraca",
      ACTIVATE: "Aktifkan",
      EXTEND_TIME: "Perpanjang waktu",
      SPECIAL_PRICE: "HARGA SPESIAL",
      BUYING: "Membeli dari HoliDex",
      HOLDING_BY:"MEMEGANG oleh",
      TYPE:"Jenis",
      OPTIONS:"Pilihan",
      DAYS_LEFT:"Hari tersisa",
      HOLDING:"Memegang",
      CAN_USED_ONCE_DURING_PROMOTION_PERIOD:"Dapat digunakan satu kali selama periode promosi",
      INSUFFICIENT_BALANCE:"Saldo tidak mencukupi",
      MFS_VESTING:"PENGEMBANGAN HC",
      TIME:"waktu",
      EXTEND:"Memperpanjang",
      SLIPPAGE_TOLERANCE:"TOLERANSI SELIP: ",
      LEVEL_ALREADY_BEEN_EXTENDED :"Level tersebut sudah</br> diperpanjang",
      TIMES_HMFS:"waktu oleh hHC",
      YOU_DONT_HAVE_RIGHT_TYPE_HMFS:"Anda tidak memiliki tipe HMF yang tepat. Anda bisa mendapatkannya menggunakan HC holding atau pertukaran",
      STOCK_INFO1: "Harga HC ditanggal dari pasar HoliDex. Hal ini berarti bahwa harga satu HC dalam DAI secara terus menerus menjadi berubah.",
      STOCK_INFO2: "Kami tidak bisa menjamin biaya transaksion akan tetap seperti yang terlihat pada layar saat membuat transaksi.",
      RENEWAL_FULL_PRICE:"Pembaruan harga penuh",
      RENEWAL_DISCOUNTED_PRICE:"Pembaruan dengan harga diskon",
      MFS_BY_DAI_exchange:"HC untuk DAI melalui pertukaran",
      EXTENDED_7_OR_MORE_TIMES:'Diperpanjang 7 kali atau lebih',
      YOU_HAVE_LEFT: "sisa chip",
      CHIPS_LEFT: "Sisa Keripik",
      COMPLETED_EXTENDED:'selesai diperpanjang',
      FOR_THE_NEXT_EXTENDED_YOU_NEED:'untuk perpanjangan berikutnya yang Anda butuhkan',
      OR_MORE_TIMES:'atau lebih kali',
    },
  },
};
