export const locale = {
  lang: 'ur',
  data: {
    LEVEL_ITEM: {
      TIER: 'درجہ',
      ACTIVE_UNTIL: 'فعال تک',
      ACTIVATE: 'چالو کریں',
      EXTENSION_TIME: 'توسیع کا وقت',
      EXTEND_TIME: 'توسیع',
      DELTA: 'ڈیلٹا',
      BADGE_READY: 'تیار',
      BADGE_EXTENSION: 'توسیع',
      BADGE_ACTIVE: 'فعال',
      BADGE_NO_ACTIVE: 'غیر فعال',
      OR: 'یا',
      CANT_EXTEND: "پہلے سے چالو ہے",
      EXTEND_TEXT: 'تک توسیع:',
    },
  },
};
