export const locale = {
  lang: 'ru',
  data: {
    SIDE_NAV_WALLET_BUYBACK: {
      BACK: "Назад",
      TITLE: "Досрочная продажа HC",
      IMPORTANTLY: "Важно",
      INFO1: "Данный инструмент позволяет вам продать ваши HC вне очереди, включая средства на балансе вестинга. Стоимость HC при использовании этого инструмента составляет 1 HC: ${price} DAI.",
      INFO2: "* При продаже монет из баланса вестинга произойдет перераспределение. Оставшаяся сумма монет на балансе вестинга после продажи будет равномерно освобождаться в течение оставшегося периода вестинга.",
      INFO3: "HC продаются в этом порядке: <ul><li>сначала свободные HC от вестинга;</li><li>затем продаются HC с баланса вестинга.</li></ul>Данный инструмент осуществляет выкуп HC лимитами. Если текущий лимит закончился то вам необходимо ожидать открытия следующего лимита.",
      YOU_GIVE: "Вы отдаете",
      AVAILABLE: "Доступно",
      MAX: "Максимум",
      YOU_RECEIVE: "Вы получаете",
      SELL: 'Продать',
      OPERATIONS: "Операции",
      COMPLETED: "Завершено",
      AMOUNT: "Общая сумма",
      NOT_AVAILABLE: "DAI недоступен",
      OFFER_YOUR_PRICE: "Предложите свою цену",
      BUYBACK_OFFER_REQUEST_YOUR_PRICE: 'Ваша заявка удовлетворена. Вы можете приобрести HC по предложенной цене.',
    },
  },
};
