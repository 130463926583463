<div class="row">
  <label class="switch">
    <input type="checkbox" id="customCheck1" (change)="change($event)" />
    <span class="slider">
      <span class="curcle-switch"> </span>
      <span class="switch-on"> </span>
    </span>
  </label>
  <label class="m-0 label-text" for="customCheck1">
    <span class="description-mfs">{{
      'SWITCH_BUTTON.' + nameLabel | translate
    }}</span></label
  >
</div>
