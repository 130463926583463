export const locale = {
  lang: 'hi',
  data: {
    MINI_GAME_MODAL: {
      TITLE: 'ऊर्जा संग्रहण उपकरण',
      START: 'अभी शुरू करें',
      CLAIM: 'दावा करें',
      BALANCE: 'आपकी शेष राशि',
      PROFIT: 'आपका मुनाफा',
      TIME_LEFT: 'समय शेष',
      WAIT: 'लहर में भाग लें ताकि NTC में इनाम प्राप्त कर सकें',
      WAIT_TX: 'लेन-देन प्रगति में है। हम बहुत जल्द आपके पुरस्कार के बारे में जानकारी अपडेट करेंगे। आप विवरण देख सकते हैं',
      ERROR_TX: 'ऐसा लगता है कि पिछले लेन-देन को संसाधित करते समय एक त्रुटि हुई। कृपया फिर से प्रयास करें। आप विवरण देख सकते हैं',
      TX_LINK: 'लेन-देन लिंक',
      TOOLTIP: 'माइन द्वारा उत्सर्जित ऊर्जा को संग्रहित करने वाला उपकरण। इस ऊर्जा का उपयोग बैटरी को चार्ज करने के लिए किया जा सकता है।\n' +
          'हर 24 घंटे में एक कार्य शुरू करें और NTC में एक निश्चित इनाम प्राप्त करें। पिछला इनाम प्राप्त करने के बाद स्वचालित रूप से एक नया कार्य शुरू हो जाएगा।\n',
      NO_ACTIVE_WAVES: 'आपके पास कोई सक्रिय तरंग नहीं है'
    },
  },
};
