export const locale = {
  lang: 'vi',
  data: {
    NOT_ACTIVATED: {
      HEADER: 'Bạn chưa kích hoạt hạng này',
      DESCRIPTION: 'Truy cập được cấp trong vòng 30 ngày',
      OVERTAKING: '⬤ Vượt qua',
      ACTIVATE: 'Kích hoạt',
    },
  },
};
