export const locale = {
  lang: 'ar',
  data: {
    PURCHASE: {
      ACTIVATE: 'لتفعيل الطبقة',
      PURCHASE: 'شراء الطبقة',
      ACCESS: 'يتم منح الوصول لمدة 30 يومًا',
    },
  },
};
