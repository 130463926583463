export const locale = {
  lang: 'fr',
  data: {
    CLAIM_MODAL: {
      CLAIM: "Réclamer",
      TITLE: "Félicitations pour le nouveau profit",
      SUBTITLE: "L'extraction des minerais est terminée, vous pouvez maintenant réclamer votre récompense",
      REWARD: "Votre récompense",
    }
  },
};
