export const locale = {
  lang: 'zh',
  data: {
    CLAIM_MODAL: {
      CLAIM: "领取",
      TITLE: "恭喜获得新利润",
      SUBTITLE: "矿石开采完成，您可以领取奖励",
      REWARD: "您的奖励",
    }
  }
  ,
};
