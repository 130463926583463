export const locale = {
  lang: 'zh',
  data: {
    EXTEND_CHOOSE_TYPE_MODAL: {
      BUTTON_BACK: '返回',
      EXTAND_TIER: '扩展等级',
      TWO_TYPES_EXTENSIONS_AVAILABLE: '有两种扩展类型可用',
      EXTEND_TIME_IN: '扩展时间 ',
      PRICE_REDUCED_BY: '价格降低 ',
      YOUR_BALANCE: '您的余额 ',
      EXTEND: '扩展 ',
      INSUFFICIENT_FUNDS: '余额不足',
    },
  },
};
