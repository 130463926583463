<!-- <datalist id="markers" style="--list-length: 3;">
    <option value="0" label="HC"></option>
  <option value="50" label="50% / 50%"></option>
  <option value="100" label="DAI"></option>

  </datalist> -->
<div>
  <div class="row datalist">
    <span class="col-4 d-flex"> HC </span>
    <span class="col-4 d-flex justify-content-center"> 50% / 50% </span>
    <span class="col-4 d-flex justify-content-end"> DAI </span>
  </div>
  <div class="input-continer">
    <input
      type="range"
      min="0"
      max="2"
      value="1"
      step="1"
      [ngModel]="typeValue"
      (ngModelChange)="changeType(+$event)"
      list="markers"
      class="n n3" />
  </div>
</div>
