export const locale = {
  lang: 'ru',
  data: {
    TABS: {
      ALL: 'Все',
      ACTIVE: 'Активные',
      COMPLETED: 'Завершенные',
    }
  },
};
