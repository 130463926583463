export const locale = {
  lang: 'ar',
  data: {
    NOT_ACTIVATED: {
      HEADER: 'لم تقم بتفعيل هذه الطبقة بعد',
      DESCRIPTION: 'يتم منح الوصول لمدة 30 يومًا',
      OVERTAKING: '⬤ التفوق',
      ACTIVATE: 'تفعيل',
    },
  },
};
