export const locale = {
  lang: 'vi',
  data: {
    EXTEND_CHOOSE_TYPE_MODAL: {
      TIER: "Cấp độ",
      ACTIVATION: "Kích hoạt",
      YOU_WILL_GET: "Bạn sẽ nhận được",
      ACTIVATE_CHIP: "Chip kích hoạt",
      NEW: "mới",
      CHIP_DESCRIPTION: "Chip để kích hoạt máy khởi động sóng lượng tử.",
      AMOUNT_CHIPS: "số lượng chip",
      ACCESS_KEY: "Khóa truy cập",
      KEY_DESCRIPTION: "Kéo dài thời gian truy cập.",
      KEY_DESCRIPTION_ACTIVATION: "Khóa truy cập cung cấp lối vào các tầng được trang bị máy móc cho phép bạn tham gia vào các quy trình khởi động sóng lượng tử, cũng như xử lý quặng và khai thác Bitforce.",
      AMOUNT_DAYS: "số ngày",
      BUNDLE_SIZE: "Kích cỡ bó",
      SELECT_PAYMENT: "Chọn phương thức thanh toán",
      PRICE: "Giá",
      BALANCE: "Số dư",
      SUPPORTED: "Được hỗ trợ bởi HoliDex",
      TOTAL: "Tổng cộng",
      ERROR_MSG: "Không đủ tiền trong bảng cân đối kế toán",
      ACTIVATE: "Kích hoạt",
      EXTEND_TIME: "Gia hạn thời gian",
      SPECIAL_PRICE: "GIÁ ĐẶC BIỆT",
      BUYING: "Mua từ HoliDex",
      HOLDING_BY:"GIỮ BỞI",
      TYPE:"Kiểu",
      OPTIONS:"Tùy chọn",
      DAYS_LEFT:"Ngày còn lại",
      HOLDING:"Giữ",
      CAN_USED_ONCE_DURING_PROMOTION_PERIOD:"Có thể sử dụng một lần trong thời gian khuyến mãi",
      INSUFFICIENT_BALANCE:"Thiếu cân bằng",
      MFS_VESTING:"HC QUYỀN SỞ HỮU",
      TIME:"thời gian",
      EXTEND:"Mở rộng",
      SLIPPAGE_TOLERANCE:"KHẢ NĂNG CHỐNG TRƯỢT: ",
      LEVEL_ALREADY_BEEN_EXTENDED :"Mức độ đã được mở rộng </br>",
      TIMES_HMFS:"lần bởi hHC",
      YOU_DONT_HAVE_RIGHT_TYPE_HMFS:"Bạn không có đúng loại hHC. Bạn có thể lấy nó bằng cách sử DỤNG tổ CHỨC HC hoặc trao đổi",
      STOCK_INFO1: "Giá HC lấy từ hội thị trường HoliDex. Điều nà có nghĩa là giá một HC trong DAI sẽ thay đổi liên tục.",
      STOCK_INFO2: "Chúng tôi không thể đảm bảo rằng giá tiếr của giao dịch sẽ giống với giá hiện tại khi tạo giao dịch.",
      RENEWAL_FULL_PRICE:"Đổi mới giá đầy đủ",
      RENEWAL_DISCOUNTED_PRICE:"Gia hạn với giá chiết khấu",
      MFS_BY_DAI_exchange:"HC CHO DAI thông qua trao đổi",
      EXTENDED_7_OR_MORE_TIMES:'Mở rộng 7 lần trở lên',
      YOU_HAVE_LEFT: "còn lại chip",
      CHIPS_LEFT: "Còn lại chip",
      COMPLETED_EXTENDED:'hoàn thành mở rộng',
      FOR_THE_NEXT_EXTENDED_YOU_NEED:'đối với phần mở rộng tiếp theo bạn cần',
      OR_MORE_TIMES:'hoặc nhiều lần hơn',
    },
  },
};
