export const locale = {
  lang: 'vi',
  data: {
    TREE: {
      FORCE_ID: 'ID Lực lượng',
      HIDE: 'Ẩn',
      SHOW_REFERRALS: 'Hiển thị người giới thiệu',
      UPLOAD: 'Tải lên 5 người giới thiệu khác',
      WALLET: 'Wallet:',
      UV_TIER: 'Hạng UV:',
      PROFIT: 'Lợi nhuận:',
    },
  },
};
