export const locale = {
  lang: 'id',
  data: {
    CLAIM_MODAL: {
      CLAIM: "Klaim",
      TITLE: "Selamat atas keuntungan baru",
      SUBTITLE: "Penambangan bijih selesai, Anda sekarang dapat mencap hadiah Anda",
      REWARD: "Hadiah Anda",
    }
  },
};
