export const locale = {
  lang: 'ru',
  data: {
    ACTIVITY: {
      ID: 'От ID',
      NEW_REFERRAL: 'Новый реферал',
      TIER: 'Тьир ',
      ACTIVATION: 'активация',
      EXTENSION: 'продление',
      GETTING_FORCECOIN: 'Получение Holichain',
      DELTA_ACTIVATION: 'Дельта активация',
      LOST_PROFIT: 'Упущенная прибыль',
      YOU: 'Вы',
    },
  },
};
