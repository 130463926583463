export const locale = {
  lang: 'id',
  data: {
    UPGRADE_WAVE_MODAL: {
      UPGRADE: 'Tingkatkan',
      FILL: 'Isi',
      FILL_INFO: 'Gunakan token NTC Anda untuk mengisi chip dalam pemrosesan bijih Anda',
      LEARN_MORE: 'Pelajari lebih lanjut',
      YOU_HAVE: "Tersedia",
      SELECT: 'Pilih',
      SET_MAX: 'Atur maksimum',
      YOU_GET: "Anda akan mendapatkan",
      BUY: 'Beli',
      AVAILABLE: 'Tersedia',
      OF: 'dari',
      ERROR: 'Anda tidak memiliki cukup NTC',
      UPGRADE_BUTTON: 'Tingkatkan di X1',
    }
  },
};
