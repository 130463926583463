export const locale = {
  lang: 'vi',
  data: {
    CLAIM_MODAL: {
      CLAIM: "Nhận",
      TITLE: "Chúc mừng với lợi nhuận mới",
      SUBTITLE: "Khám phá khoáng sản hoàn tất, bạn có thể nhận phần thưởng của mình",
      REWARD: "Phần thưởng của bạn",
    }
  },
};
