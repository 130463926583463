export const locale = {
  lang: 'ru',
  data: {
    LINKS_PANEL: {
      DOWNLOAD_TITLE: "Uniteverse GameAf",
      DOWNLOAD_BUTTON: "Скачать PDF",
      LORE_LINK: "https://cdn.meta-force.space/uniteverse/ru.pdf",
      LANDING_BUTTON: "Legends of Uniteverse",
      LANDING_LINK: "/pages/lore",
    },
  },
};
