export const locale = {
  lang: 'vi',
  data: {
    TABLE: {
      TYPE: 'Loại',
      FORCE_ID: 'ID Lực lượng',
      WALLET: 'Ví tiền',
      PROGRAM: 'Chương trình',
      DATE: 'Ngày',
      AMOUNT: 'Số tiền',
      NEW_REFERRAL: 'Người giới thiệu mới',
      TIER: 'Hạng ',
      ACTIVATION: 'kích hoạt',
      EXTENSION: 'mở rộng',
      GETTING_FORCECOIN: 'Nhận Holichain',
      DELTA_ACTIVATION: 'Kích hoạt Delta',
      MARKETING: 'Marketing',
      REFERRAL: 'Giới thiệu',
      ADDITIONAL_ACQUISITION: 'Mua thêm',
      LOST_PROFIT: 'Lợi nhuận bị mất',
      YOU: 'Bạn',
    },
  },
};
