export const locale = {
  lang: 'vi',
  data: {
    PURCHASE: {
      ACTIVATE: 'Để kích hoạt hạng',
      PURCHASE: 'mua hạng',
      ACCESS: 'Truy cập được cung cấp trong 30 ngày',
    },
  },
};
