export const locale = {
  lang: 'ur',
  data: {
    SIDE_NAV_WALLET_ACTION: {
      BACK: 'پیچھے',
      SELL_MFC: 'HC بیچیں',
      IMPORTANTLY: 'اہم طور پر',
      INFO: 'یہاں آپ اپنے HC کو فی 1 HC کے لیے 2.77 DAI کی قیمت پر فروخت کے لئے پیش کر سکتے ہیں۔ آپ کے HC ان صارفین کو بیچے جائیں گے جو Uniteverse میں ٹائرز کو چالو کرنے کے لئے HC نہیں رکھتے۔ درخواستوں کو قطار کے ترتیب میں عملدرآمد کیا جاتا ہے۔ جزوی فروخت کی صورت میں، درخواست کو قطار کے آخر میں بھیج دیا جاتا ہے۔',
      YOU_GIVE: 'آپ دیتے ہیں',
      AVAILABLE: 'دستیاب ہے',
      MAX: 'زیادہ سے زیادہ',
      YOU_RECEIVE: 'آپ وصول کریں گے',
      SELL: 'بیچیں',
      OPEN: 'کھولیں',
      QUEUE: 'آپ کی باری کے ذریعے',
      OPERATIONS: 'آپریشنز',
      CANCEL_ORDER: 'آرڈر منسوخ کریں',
      COMPLETED: 'مکمل ہوگیا',
      AMOUNT: 'کل رقم',
      BUYBACK_INFO: "کیا آپ بغیر قطار میں لگے فوری طور پر بیچنا چاہتے ہیں؟ مزید معلومات کے لیے",
      BUYBACK_LINK: "یہاں کلک کریں",
    },
  },
};
