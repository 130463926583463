export const locale = {
  lang: 'hi',
  data: {
    EXTEND_CHOOSE_TYPE_MODAL: {
      BUTTON_BACK: 'वापस',
      EXTAND_TIER: 'टियर का विस्तार',
      TWO_TYPES_EXTENSIONS_AVAILABLE: 'दो प्रकार के विस्तार उपलब्ध हैं',
      EXTEND_TIME_IN: 'में समय बढ़ाएं ',
      PRICE_REDUCED_BY: 'कीमत कम हो गई ',
      YOUR_BALANCE: 'आपका शेष ',
      EXTEND: 'विस्तार करें ',
      INSUFFICIENT_FUNDS: 'अपर्याप्त फ़ंड्स',
    },
  },
};
