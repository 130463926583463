export const locale = {
  lang: 'ru',
  data: {
    SELECT_CHIP_MODAL: {
      TITLE: 'Attention',
      INFO: 'You have 10 level 2 chips. How many do you want to activate?',
      YES: 'Yes',
      NO: 'No',
    },
  },
};
