export const locale = {
  lang: 'vi',
  data: {
    VESTING_TARIFFS: {
      TITLE: 'Chọn gói cước',
      BALANCES: 'Số dư của bạn',
      NO_VESTING_BALANCE_INFO: 'Bạn không có số dư tích lũy trên tài khoản của mình. Không thể tích lũy mà không có số dư.',
      TARIFF_1_NAME: 'Mức cước 2 tháng',
      TARIFF_2_NAME: 'Gói cước 1 năm',
      TARIFF_3_NAME: 'Gói cước 2 năm',
      TARIFF_4_NAME: 'Gói cước 3 năm',
      TARIFF_5_NAME: 'Gói cước 4 năm',
      TARIFF_6_NAME: 'Gói cước 5 năm',
      DURATION: 'Thời gian: ',
      TARIFF_1_DURATION: '2 tháng',
      TARIFF_2_DURATION: '1 năm',
      TARIFF_3_DURATION: '2 năm',
      TARIFF_4_DURATION: '3 năm',
      TARIFF_5_DURATION: '4 năm',
      TARIFF_6_DURATION: '5 năm',
      TARIFF_1_DESCRIPTION: 'Nếu bạn chọn mức cước này, 50% phí tài trợ sẽ được thu từ số dư còn lại trong tài khoản HC vesting của bạn. 50% tổng HC sẽ có sẵn.',
      TARIFF_2_DESCRIPTION: 'Khi chọn gói cước này, bạn sẽ thanh toán 30% số dư tích lũy HC còn lại*. Tổng cộng sẽ còn lại 70% HC.',
      TARIFF_3_DESCRIPTION: 'Khi chọn gói cước này, bạn sẽ thanh toán 15% số dư tích lũy HC còn lại*. Tổng cộng sẽ còn lại 85% HC.',
      TARIFF_4_DESCRIPTION: 'Gói cước tiêu chuẩn. Không yêu cầu chi phí và không mang lại HC bổ sung.',
      TARIFF_5_DESCRIPTION: 'Cho phép giữ nguyên số dư tích lũy HC hiện tại và chia sẻ thêm 30% từ quỹ tài trợ.',
      TARIFF_6_DESCRIPTION: 'Cho phép giữ nguyên số dư tích lũy HC hiện tại và chia sẻ thêm 70% từ quỹ tài trợ.',
      TARIFFS_COMMON_INFO: 'Vesting HC bao gồm hai giai đoạn. Giai đoạn đầu tiên giải phóng 10% số dư của mỗi người dùng, với việc giải phóng thêm HC để gia hạn các cấp độ trong tháng đầu tiên. Giai đoạn thứ hai của vesting là giải phóng số dư còn lại 90% hoặc ít hơn nếu đã tham gia giải phóng HC để gia hạn các cấp độ. Lựa chọn gói cước vesting chỉ áp dụng cho giai đoạn hai. Gói cước 3 năm được kích hoạt mặc định, nếu bạn không có ý định thay đổi, bạn không cần chọn gì trên trang này.',
      TARIFFS_COMMON_INFO_WARNING: 'Lựa chọn gói cước chỉ được thực hiện một lần. Không thể thay đổi gói cước lần thứ hai. Ví dụ: hiện tại gói cước 3 năm được chọn mặc định. Bạn chọn gói cước 4 năm, sau đó bạn sẽ không thể thay đổi nó.',
      TARIFFS_INFO: 'Tất cả các token tích lũy sẽ được phát hành trước khi kỳ hạn kết thúc.',
      TARIFFS_DISABLED_BIG: 'Gói cước 4 và Gói cước 5 sẽ được mở sau, sau khi tính toán số lượng trong quỹ.',
      TARIFFS_DISABLED_SMALL: "Các gói cước cho 2 tháng, 1 năm và 2 năm sẽ có sẵn sau khi việc tính toán khối lượng quỹ hoàn tất.",
      SELECT_TARIFF: 'Chọn gói cước',
      CURRENT_TARIFF: 'Gói cước hiện tại',
      DEFAULT: 'mặc định',
      USER_WILL_RECEIVE: 'Bạn sẽ nhận được cho đến cuối kỳ hạn:',
      FUND_WILL_RECEIVE: 'Thanh toán của bạn vào quỹ tài trợ:',
      FUND_RECEIVED: 'Trong quỹ:',
      FUNDING_AMOUNT: 'Số tiền quỹ',
      BURN: '15% sẽ bị đốt cháy',
      BUYBACK_INFO: "Nếu bạn không muốn chờ đợi để nhận xu từ Messenger, bạn có thể tìm hiểu thêm về các điều khoản bổ sung",
      BUYBACK_LINK: "bằng cách nhấp vào đây",
    },
  },
};
