export const locale = {
  lang: 'ar',
  data: {
    EXTEND_CHOOSE_TYPE_MODAL: {
      TIER: "المستوى",
      ACTIVATION: "التفعيل",
      YOU_WILL_GET: "ستحصل على",
      ACTIVATE_CHIP: "شريحة التفعيل",
      NEW: "جديد",
      CHIP_DESCRIPTION: "رقاقة لتفعيل آلة بدء الموجة الكمية.",
      AMOUNT_CHIPS: "عدد الرقائق",
      ACCESS_KEY: "مفتاح الوصول",
      KEY_DESCRIPTION: "تمديد وقت الوصول.",
      KEY_DESCRIPTION_ACTIVATION: "توفر مفتاح الوصول دخولًا إلى المستويات المجهزة بآلات تتيح لك المشاركة في عمليات إطلاق الموجات الكمية، وكذلك معالجة الخام واستخراج بيتفورس.",
      AMOUNT_DAYS: "عدد الأيام",
      BUNDLE_SIZE: "حجم الحزمة",
      SELECT_PAYMENT: "اختر طريقة الدفع",
      PRICE: "السعر",
      BALANCE: "الرصيد",
      SUPPORTED: "يدعمها HoliDex",
      TOTAL: "المجموع",
      ERROR_MSG: "لا توجد أموال كافية في الميزانية العمومية",
      ACTIVATE: "تفعيل",
      EXTEND_TIME: "تمديد الوقت",
      SPECIAL_PRICE: "سعر خاص",
      BUYING: "HoliDex الشراء من",
      HOLDING_BY:"الاحتفاظ بـ",
      TYPE:"يكتب",
      OPTIONS:"خيارات",
      DAYS_LEFT:"ايام متبقية",
      HOLDING:"تحتجز",
      CAN_USED_ONCE_DURING_PROMOTION_PERIOD:"يمكن استخدامه مرة واحدة خلال فترة العرض الترويجي",
      INSUFFICIENT_BALANCE:"رصيد غير كاف",
      MFS_VESTING:"HC استثمار",
      TIME:"وقت",
      EXTEND:"يمتد",
      SLIPPAGE_TOLERANCE:"تحمل الانزلاق: ",
      LEVEL_ALREADY_BEEN_EXTENDED :"ممتد </br> لقد تم بالفعل المستوى",
      TIMES_HMFS:"hHC مرات بواسطة",
      YOU_DONT_HAVE_RIGHT_TYPE_HMFS:"يمكنك الحصول عليه باستخدام خدمة HC أو البورصة.ليس لديك النوع الصحيح من hHC",
      STOCK_INFO1: 'استخلاص سعر الـ HC من بورسة HoliDex. هذا يعنى أن سعر واحد من الـ HC في DAI تغير الشديد.',
      STOCK_INFO2: 'لا يُ garantee أن سعر الضرب إذا كان بعد إنشاء المزاد يكون نفسه الموجه في هذا الشاشية.',
      RENEWAL_FULL_PRICE:"التجديد بالسعر الكامل",
      RENEWAL_DISCOUNTED_PRICE:"التجديد بسعر مخفض",
      MFS_BY_DAI_exchange:"HC لـ DAI عبر التبادل",
      EXTENDED_7_OR_MORE_TIMES:'تم تجديده 7 مرات أو أكثر',
      YOU_HAVE_LEFT: "الرقائق المتبقية",
      CHIPS_LEFT: "الرقائق المتبقية",
      COMPLETED_EXTENDED:'تم الانتهاء من التمديد',
      FOR_THE_NEXT_EXTENDED_YOU_NEED:'للتمديد القادم الذي تحتاجه',
      OR_MORE_TIMES:'أو مرات أكثر',
    },
  },
};
