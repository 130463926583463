export const locale = {
  lang: 'vi',
  data: {
    SIDE_NAV_HOLD_MFS_WALLET: {
      BACK: 'Mặt sau',
      HOLD_MFS:'Giữ MFS',
      HOLD_HMFS:'Giữ hHC',
      HOLD: 'GIỮ',
      DAY: 'ngày',
      GET: 'LẤY',
      INNER_BALANCE: 'Cân bằng bên trong',
      HMFS_TO_MFS: 'hHC sang HC',
      TAKE_MFS:'LẤY HC',
      TAKE_HMFS:'LẤY hHC',
    },
  },
};
