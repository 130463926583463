export const locale = {
  lang: 'ru',
  data: {
    CONFIRM_BUYBACK_MODAL: {
      TITLE: 'Внимание',
      INFO: 'Вы подтверждаете, что ознакомлены с установленной ценой продажи: 1 HC: {{price}} DAI, и соглашаетесь с ней. Вы также подтверждаете, что не имеете претензий по данному курсу обмена и к платформе.',
      AGREEMENT: 'Подтверждаю всё',
      YES: 'Да',
      NO: 'Нет',
    },
  },
};
