export const locale = {
  lang: 'id',
  data: {
    UPGRADE_CHIP_MODAL: {
      TITLE: 'Select type upgrade',
      EXPAND: 'Expand Chip',
      FILL: 'Fill Chip',
    },
  },
};
