export const locale = {
  lang: 'en',
  data: {
    MARKETING: {
      NEW: 'new',
      OVERTAKING: '⬤ Overtaking',
      DESCRIPTION:
        "Descriptionative desctription of the description's description for descripticative describing for the description",
    },
  },
};
