export const locale = {
  lang: 'en',
  data: {
    TABS: {
      ALL: 'All',
      ACTIVE: 'Active',
      COMPLETED: 'Completed',
    },
  },
};
