export const locale = {
  lang: 'vi',
  data: {
    ACTIVITY: {
      ID: 'Từ ID',
      NEW_REFERRAL: 'Người giới thiệu mới',
      TIER: 'Hạng ',
      ACTIVATION: 'kích hoạt',
      EXTENSION: 'mở rộng',
      GETTING_FORCECOIN: 'Nhận Holichain',
      DELTA_ACTIVATION: 'Kích hoạt Delta',
      LOST_PROFIT: 'Lợi nhuận bị mất',
      YOU: 'Bạn',
    },
  },
};
