export const locale = {
  lang: 'ur',
  data: {
    EXTEND_CHOOSE_TYPE_MODAL: {
      BUTTON_BACK: 'Back',
      EXTAND_TIER: 'Extand of tier',
      TWO_TYPES_EXTENSIONS_AVAILABLE: 'Two types of extensions are available',
      EXTEND_TIME_IN: 'Extend time in ',
      PRICE_REDUCED_BY: 'Price reduced by ',
      YOUR_BALANCE: 'Your balance ',
      EXTEND: 'Extend ',
      INSUFFICIENT_FUNDS: 'insufficient funds',
    },
  },
};
