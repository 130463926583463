export const locale = {
  lang: 'en',
  data: {
    MARKETING_TABEL: {
      FORCE_ID: 'Holiverse ID',
      ORBIT: 'Orbit',
      WALLET: 'Wallet:',
      UV_TIER: 'UV tier:',
      PROFIT: 'Profit:',
      LINE: 'Line',
    },
  },
};
