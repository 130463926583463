export const locale = {
  lang: 'ru',
  data: {
    FILTER_ORBIT: {
      LEVEL: 'Уровень:',
      ALL: 'Все',
      SHOW_LEVEL_LIST: 'Список уровней',
    },
  },
};
