<div class="row h-100 m-0">
    <div
      class="col-12 d-flex align-items-center justify-content-between header-menu">
      <div
        class="back-arrow-wallet d-flex align-items-center cursor-pointer"
        (click)="closeSlideNavAction()">
        <div class="icon"></div>
        {{ 'SIDE_NAV_HOLD_MFS.BACK' | translate }}
      </div>
      <div class="header-title">
        {{ isHold
              ? ('SIDE_NAV_HOLD_MFS.HOLD' | translate)
              : ('SIDE_NAV_HOLD_MFS.GET' | translate) + ( ishMFS ? ' hHC':' HC' )
        }}
      </div>
      <img
        class="button-close cursor-pointer button-black"
        (click)="closeALLSlideNav(false)"
        src="/assets/icon/closeButton.svg"
        alt="" />
    </div>
    <div class="col-12 height-container-menu">
      <div class="row m-0">
        <div class="col-12 number-container">
          <div class="row m-0">
            <div class="col-5 p-0">
              <input
                type="number"
                class="text-white text-number-size margin-bottom-8 w-100"
                [ngModel]="amountTransaction"
                aria-label="Amount"
                placeholder="0"
                (ngModelChange)="changeTotal($event)" />
              <div class="small-text">
                <span class="text-grey text-upercase">
                  {{ isHold
                        ? ('SIDE_NAV_HOLD_MFS.BALANCE' | translate)
                        : ('SIDE_NAV_HOLD_MFS.AVAILABLE' | translate)
                  }}:</span>
                <span class="text-grey">
                  {{ isHold
                        ? ishMFS ? +selectedhMFS.value : +this.balanceMFS
                        : coin.amount
                  }}
                </span>
              </div>
            </div>
            <div class="col-7 p-0 d-flex justify-content-end align-items-center">
              <button class="button-grey button-max" (click)="setMax()">
                {{ 'SIDE_NAV_HOLD_MFS.MAX' | translate }}
              </button>
              <!-- <span class=" conteiner-coning-soon">
                          
                              <div class="coning-soon coning-soon-top">
                                  {{ 'SIDE_NAV_WALLET_VALUE.CONING_SOON' | translate }}
                              </div> -->
  
              <a
                class="select-coin d-flex align-items-center text-white w-100"
                >
                <div class="margin-right-8px">
                  <app-crypto-tokens
                    [width]="32"
                    [height]="32"
                    [imgName]="!ishMFS ? 'forcecoin' : 'hh'">
                  </app-crypto-tokens>
                </div>
                <select
                  *ngIf="ishMFS && isHold; else other_variant"
                  style="min-width: 104px;"
                  [ngModel]="selectedhMFS.index"
                  (ngModelChange)="changehMFS(+$event)"
                  class="form-control w-100"
                  id="routeSelect"
                >
                  <option *ngFor="let item of hMFSBalance; let i = index" [value]="i">
                    {{ item.newName }}
                  </option>
                </select>
              <ng-template #other_variant>
                <div class="col p-0" >
                <span class="text-grey text-uppercase font-size-12"*ngIf="!isHold">
                  {{ 'SIDE_NAV_HOLD_MFS.YOU_GET' | translate }}
                </span>
                <span class="big-text" *ngIf="!ishMFS">
                  {{ 'HC' }}
                </span>
                <span class="big-text" *ngIf="ishMFS && !isHold">
                  {{ coin.newName }}
                </span>
              </div>
            </ng-template>
              </a>
              <!-- </span> -->
            </div>
          </div>
        </div>
        <div class="col-12 p-0">
          <button *ngIf="selectedhMFS.entryLevel < 8"
            [disabled]="isLoading"
            class="btn-orange w-100"
            (click)="isHold ? hold() : openModal(coin)">
            <span
              *ngIf="isLoading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"></span>
            {{ isHold ? ('SIDE_NAV_HOLD_MFS.HOLD' | translate) : 'SIDE_NAV_HOLD_MFS.GET' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  

<app-get-hmfs-confim-modal
  [isOpen]="isModalOpen"
  [coin]="getCurrenthMFS"
  [isHold]="isHold"
  [getAmmount]="!this.isHold?amountTransactionTotal:-1"
  (openEvent)="isModalOpen = $event"
  (onSucceedEvent)="onSucceed($event)"
></app-get-hmfs-confim-modal>