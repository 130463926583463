export const locale = {
  lang: 'vi',
  data: {
    EXTEND_CHOOSE_TYPE_MODAL: {
      BUTTON_BACK: 'Quay lại',
      EXTAND_TIER: 'Mở rộng của hạng',
      TWO_TYPES_EXTENSIONS_AVAILABLE: 'Có hai loại mở rộng có sẵn',
      EXTEND_TIME_IN: 'Mở rộng thời gian trong ',
      PRICE_REDUCED_BY: 'Giá giảm đi ',
      YOUR_BALANCE: 'Số dư của bạn ',
      EXTEND: 'Mở rộng ',
      INSUFFICIENT_FUNDS: 'số dư không đủ',
    },
  },
};
