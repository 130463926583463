export const locale = {
  lang: 'fr',
  data: {
    MARKETING: {
      NEW: 'nouveau',
      OVERTAKING: '⬤ Dépassement',
      DESCRIPTION:
        'Description détaillée de la description de la description pour une description descriptive de la description',
    },
  },
};
