export const locale = {
  lang: 'ru',
  data: {
    CONFIRM_MODAL: {
      TITLE: 'Внимание',
      SUBTITLE: 'Внимание',
      TARIFF_5_INFO: 'При выборе данного тарифа будет взята оплата процента фандинга в размере 50% от оставшегося* Вашего общего баланса вестинга HC.',
      TARIFF_1_INFO: 'При выборе данного тарифа будет взята оплата процента фандинга в размере 30% от оставшегося* Вашего общего баланса вестинга HC.',
      TARIFF_2_INFO: 'При выборе данного тарифа будет взята оплата процента фандинга в размере 15% от оставшегося* Вашего общего баланса вестинга HC.',
      TARIFF_0_INFO: 'Вы выбираете тарифный план на три года.',
      TARIFF_3_INFO: 'Вы выбираете тарифный план на четыре года.',
      TARIFF_4_INFO: 'Вы выбираете тарифный план на пять лет.',
      TARIFF_AGREEMENT: 'Я подтверждаю что внимательно ознакомился со всеми условиями выбора тарифного плана и фандинга. Я соглашаюсь на данные условия.',
      INFO: 'Выбор тарифа осуществляется единоразово. Поменять выбор тариф после данного выбора будет невозможно.',
      ARE_YOU_SURE: 'Вы хотите применить новый тарифный план?',
      YES: 'Да',
      NO: 'Нет',
    },
  },
};
