export const locale = {
  lang: 'fr',
  data: {
    SIDE_NAV_HOLD_MFS_WALLET: {
      BACK: 'Dos',
      HOLD_MFS:'Maintenez HC',
      HOLD_HMFS:'Maintenez hHC',
      HOLD: 'PRISE',
      DAY: 'jour',
      GET: 'OBTENIR',
      INNER_BALANCE: 'Équilibre intérieur',
      HMFS_TO_MFS: 'hHC vers HC',
      TAKE_MFS:'PRENEZ HC',
      TAKE_HMFS:'PRENEZ hHC',
    },
  },
};
