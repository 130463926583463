<div class="download-container -hide-mobile">
    <div class="info">
        <div class="icon"></div>
        <div class="text">{{ 'LINKS_PANEL.DOWNLOAD_TITLE' | translate }}</div>
    </div>
    <div class="buttons">
        <!-- <a [href]="'LINKS_PANEL.LANDING_LINK' | translate" class="download-button">
            <div class="text">{{ 'LINKS_PANEL.LANDING_BUTTON' | translate }}</div>
        </a> -->
        <a target="_blank" [href]="'LINKS_PANEL.LORE_LINK' | translate" download="aboutLORE.pdf" class="download-button">
            <div class="icon"></div>
            <div class="text">{{ 'LINKS_PANEL.DOWNLOAD_BUTTON' | translate }}</div>
        </a>
    </div>
</div>