export const locale = {
  lang: 'ar',
  data: {
    SIDE_NAV_HOLD_MFS_WALLET: {
      BACK: 'خلف',
      HOLD_MFS:'عقد MFS',
      HOLD_HMFS:'عقد hHC',
      HOLD: 'يمسك',
      DAY: 'يوم',
      GET: 'يحصل',
      INNER_BALANCE: 'التوازن الداخلي',
      HMFS_TO_MFS: 'HC إلى hHC',
      TAKE_MFS:'خذ HC',
      TAKE_HMFS:'خذ hHC',
    },
  },
};
