export const locale = {
  lang: 'id',
  data: {
    TABS: {
      ALL: 'Semua',
      ACTIVE: 'Aktif',
      COMPLETED: 'Selesai',
    }
  },
};
