export const locale = {
  lang: 'zh',
  data: {
    EXTEND_CHOOSE_TYPE_MODAL: {
      TIER: "层级",
      ACTIVATION: "激活",
      YOU_WILL_GET: "你将获得",
      ACTIVATE_CHIP: "激活芯片",
      NEW: "新的",
      CHIP_DESCRIPTION: "用于激活量子波启动机器的芯片。",
      AMOUNT_CHIPS: "筹码数量",
      ACCESS_KEY: "访问密钥",
      KEY_DESCRIPTION: "延长访问时间。",
      KEY_DESCRIPTION_ACTIVATION: "访问密钥提供进入配备机器的层级，允许您参与量子波启动过程，以及处理矿石和开采Bitforce。",
      AMOUNT_DAYS: "天数",
      BUNDLE_SIZE: "捆绑包大小",
      SELECT_PAYMENT: "选择支付方式",
      PRICE: "价格",
      BALANCE: "余额",
      SUPPORTED: "由HoliDex支持",
      TOTAL: "总计",
      ERROR_MSG: "资产负债表上的资金不足",
      ACTIVATE: "激活",
      EXTEND_TIME: "延长时间",
      SPECIAL_PRICE: "特别价格",
      BUYING: "從HoliDex購買",
      HOLDING_BY:"持有者",
      TYPE:"類型",
      OPTIONS:"選項",
      DAYS_LEFT:"剩餘天數",
      HOLDING:"保持",
      CAN_USED_ONCE_DURING_PROMOTION_PERIOD:"優惠期間可使用一次",
      INSUFFICIENT_BALANCE:"餘額不足",
      MFS_VESTING:"HC 歸屬",
      TIME:"時間",
      EXTEND:"延長",
      SLIPPAGE_TOLERANCE:"滑移公差: ",
      LEVEL_ALREADY_BEEN_EXTENDED :"等級已擴充</br>",
      TIMES_HMFS:"次 hHC",
      YOU_DONT_HAVE_RIGHT_TYPE_HMFS:"您没有正确类型的hmf。 您可以使用HC持有或交换获得它",
      STOCK_INFO1: "HC的价格来自HoliDex交易所，这意味着每一枚DAI内的1个HC的价格会不断变化。",
      STOCK_INFO2: "我们无法保证创建交易时的手续费将与当前屏幕上显示的 Handling Fee 完全相同。",
      RENEWAL_FULL_PRICE:"全額續訂",
      RENEWAL_DISCOUNTED_PRICE:"以折扣價續訂",
      MFS_BY_DAI_exchange:"透過交易所獲取 DAI 的 HC",
      EXTENDED_7_OR_MORE_TIMES:'續訂7次或以上',
      YOU_HAVE_LEFT: "剩余芯片",
      CHIPS_LEFT: "剩余筹码",
      COMPLETED_EXTENDED:'完成擴充',
      FOR_THE_NEXT_EXTENDED_YOU_NEED:'下次您需要的擴展',
      OR_MORE_TIMES:'或更多次',
    },
  },
};
