export const locale = {
  lang: 'hi',
  data: {
    NOT_ACTIVATED: {
      HEADER: 'आपने इस तह अभी तक सक्रिय नहीं किया है',
      DESCRIPTION: '30 दिनों के लिए पहुंच दी गई है',
      OVERTAKING: '⬤ ओवरटेकिंग',
      ACTIVATE: 'सक्रिय करें',
    },
  },
};
