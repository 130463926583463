export const locale = {
  lang: 'ru',
  data: {
    CLAIM_MODAL: {
      CLAIM: "Получить",
      TITLE: "Поздравляем с новой прибылью",
      SUBTITLE: "Добыча руды завершена, теперь вы можете получить вашу награду",
      REWARD: "Ваша награда",
    }
  },
};
